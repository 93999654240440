import { User } from './../../models/user';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  user: User;
  constructor( private router: Router ) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logout(){

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigateByUrl('/login');
  }

  backOffice(){

    this.router.navigateByUrl('/backrep/home');
  }

  goPreview(){}
}
