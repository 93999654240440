<!--Card-->
<mdb-card cascade="true" class="card-project z-depth-5" (click)="linkSection()" *ngIf="objectx.type == 'backoffice' || objectx.type == 'platform' || objectx.type == 'report' || objectx.type == 'reportCard'">

    <!--Card image-->
    <div class="view view-cascade gradient-card-header header-card">
        <!-- <h2 class="card-header-title card-title">{{ objectx.name }}</h2> -->
        <h2 class="card-header-title card-title" >{{ objectx.name }}</h2>
    </div>
</mdb-card>
<!--/.Card-->


<!--Card-->
<mdb-card cascade="true" class="card-project z-depth-5" (click)="linkSection()" *ngIf="objectx.visible == true && (objectx.type == 'service' || objectx.type == 'project')">

    <!--Card image-->
    <div class="view view-cascade gradient-card-header header-card">
        <!-- <h2 class="card-header-title card-title">{{ objectx.name }}</h2> -->
        <h2 class="card-title" *ngIf="!objectx.url">{{ objectx.name }}</h2>
        <img src="{{ objectx.img }}" class="p-0" width="135" *ngIf="objectx.url"> 
    </div>
</mdb-card>
<!--/.Card-->

<!--Card-->
<mdb-card cascade="true" class="card-project z-depth-5" *ngIf="objectx.visible == false && (objectx.type == 'service' || objectx.type == 'project')">

    <!--Card image-->
    <div class="view view-cascade gradient-card-header header-card-disabled">
        <!-- <h2 class="card-header-title card-title">{{ objectx.name }}</h2> -->
        <h2 class="card-header-title card-title">{{ objectx.name }}</h2>
    </div>
</mdb-card>
<!--/.Card-->


<!--Card-->
<mdb-card cascade="true" class="card-project z-depth-5" (click)="linkSection()" *ngIf="objectx.visible == true && (objectx.type == 'company')">

    <!--Card image-->
    <div class="view view-cascade gradient-card-header header-card">
        <!-- <h2 class="card-header-title card-title">{{ objectx.name }}</h2> -->
        <h2 class="card-title" >{{ objectx.name }}</h2>
    </div>
</mdb-card>
<!--/.Card-->

