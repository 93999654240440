<main class="background-preview" style="height: 91vh;">
  <div class="container">
    <div class="d-flex justify-content-center icon-preview">
      <img src="/assets/aplications.svg" width="110px" alt="image">
    </div>
    <div class="div-preview text-center">
      <h1 class="text-center title-preview px-0 mx-0">Aplicaciones</h1>
    </div>
    <div class="card-columns container pb-1 card-config">

      <app-card (productoSeleccionado)="verProducto( $event )" [platform]="platform " [index]="i+1"
        *ngFor="let platform of platforms; let i=index ">
      </app-card>

    </div>
  </div>
</main>

<!-- 
<div class="header">
  <div class="title">Power BI Embedded Angular Component demo</div>
</div>

<div class="controls" *ngIf="isEmbedded; else embedReportFlow">
  <button (click)="deleteVisual()">Delete Visual</button>
  <button (click)="hideFilterPane()">Hide Filter Pane</button>
  <button (click)="setDataSelectedEvent()">Set Event</button>
  <div class="display-message" #status>{{ displayMessage }}</div>
</div>
<ng-template #embedReportFlow>
  <div class="display-message position" #status>
    <label>{{ displayMessage }}</label>
  </div>
  <div class="embed-report">
    <button (click)="embedReport()" id="embed-action" #embedReportBtn>Embed Report</button>
  </div>
  <div class="separator"></div>
</ng-template>

<powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag"
  [eventHandlers]="eventHandlersMap">
</powerbi-report>

<div class="footer">
  <div class="footer-text">
    GitHub:
    <a href="https://github.com/microsoft/powerBI-client-angular"> https://github.com/microsoft/PowerBI-client-angular
    </a>
  </div>
</div> -->