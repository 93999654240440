<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark orange darken-3" [containerInside]="false">

  <!-- Navbar brand -->
  <!-- <mdb-navbar-brand><a class="navbar-brand" (click)="goPreview()">
    <img class="p-0 m-0 pl-2" src="/assets/logoNavbarWhite3.svg" width="155" alt="">
 </a></mdb-navbar-brand> -->
 <mdb-breadcrumb class="breadcrumbs">
  <mdb-breadcrumb-item routerLink="/rep/units">Unidades</mdb-breadcrumb-item>
  <mdb-breadcrumb-item routerLink="/rep/servicesUnit">Servicios</mdb-breadcrumb-item>
  <mdb-breadcrumb-item routerLink="/rep/preview">Módulos</mdb-breadcrumb-item>
  <mdb-breadcrumb-item class="active">Visualización</mdb-breadcrumb-item>
</mdb-breadcrumb>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <!-- <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Pricing</a>
      </li>

      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Basic dropdown<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
        </div>
      </li>

    </ul> -->
    <!-- Links -->

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <!-- <li class="nav-item">
        <a class="nav-link">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link user-name">
          {{ user.name1 + ' ' + user.last_name1 }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <div class="btn-group" mdbDropdown>
            <mdb-icon fas icon="user" mdbDropdownToggle></mdb-icon>
            <div class="dropdown-menu dropdown-menu-right dropdown-primary">
              <!-- <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a> -->
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item" (click)="backOffice()">Backoffice</a>
              <a class="dropdown-item" (click)="logout()">Cerrar Sesion</a>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->