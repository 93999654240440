import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { GisComponent } from '../pages/gis/gis.component';
import { ViewsComponent } from './views.component';

const routes: Routes = [
  { 
    path: 'views', 
    component: ViewsComponent,
    canActivate: [ AuthGuard ],
    
    children: [
      // { path: '', component: HomeComponent},
      { path: 'gis', component: GisComponent},
      // { path: 'elerts', component: AlertsComponent},

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewsRoutingModule { }
