

<main class="background-preview" style="height: 91vh;">
    <div class="container">
        <div class="d-flex justify-content-center icon-preview">
            <img src="/assets/monitoring.svg" width="100px" alt="image">
        </div>
        <div class="div-preview text-center">
            <h1 class="text-center title-preview px-0 mx-0">Monitoreo</h1>
        </div>
        <div class="card-columns container pb-5 card-config">

            <app-card (productoSeleccionado)="verProducto( $event )" [platform]="platform " [index]="i+1"
                *ngFor="let platform of platforms; let i=index ">
            </app-card>

        </div>
    </div>
</main>
