
<!--Main Layout-->
<main class="background-preview animated fadeIn fast">
    <div class="main-view">
        <mdb-breadcrumb customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item routerLink="/backrep/home" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Backoffice</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Compañias</mdb-breadcrumb-item>
        </mdb-breadcrumb>
        <div class="container">
            <div class="icon-preview pb-3">
                <img src="/assets/back/companies.svg" width="120px" alt="image">
                <h1 class="text-center title-view pl-3 mx-0">Compañias</h1>
            </div>
            <div class="table-div mt-2 pb-3 z-depth-5">
                <div class="table-header">
                    <div style="width: 45px; height: 45px;"></div>
                    <h2 class="title-header">Registro de Compañias</h2>
                    <button mdbBtn type="button" class="button-add" (click)="showAddCompany()" mdbWavesEffect>
                        <mdb-icon fas icon="plus-circle" floating="true" size="2x"></mdb-icon>
                      </button>
                </div>
                <div class="table-body container">
                    <app-table [data]="companies" [stateModal]="stateModal" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
                     (reponseStateModalExclusion)="reponseStateModal($event)"
                     (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
                     (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table>
                </div>
            </div>


        </div>

    </div>
</main>
<!--/Main layout-->

<div mdbModal #modalAddCompany="mdbModal" class="modal fade right" id="modalCgm" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
<div class="modal-dialog modalUser" role="document">
    <div class="modal-content">
        <div class="modal-header head-modals">
            <h2 class="modal-title w-100 title-modals" id="myModalLabel">Agregar Compañia</h2>
            <button type="button" class="close x-modals" data-dismiss="modal" aria-label="Close"
                (click)="modalAddCompany.hide()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body modals-body container">
            <form class="form-horizontal p-t-20 text-modals" [formGroup]="companyForm"
                (submit)="submitCompany()">

                <div class="row">
                    <div class="col-md-6 md-form md-outline">
                        <input id="form-lg" formControlName="name" id="name" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Nombre</label>
                        <mdb-error *ngIf="name.invalid && (name.dirty || name.touched)" style="color: red;" >Nombre ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="name.valid && (name.dirty || name.touched)" style="color: green;">Nombre ingresado es valido</mdb-success>
                    </div>
                    <div class="col-md-6 md-form md-outline">
                        <input id="form-lg" formControlName="initials" id="initials" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Siglas</label>
                        <mdb-error *ngIf="initials.invalid && (initials.dirty || initials.touched)" style="color: red;">Sigla ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="initials.valid && (initials.dirty || initials.touched)" style="color: green;">Sigla ingresado es valido</mdb-success>
                    </div>
                    <div class="col-md-6 md-form md-outline">
                        <input id="form-lg" formControlName="country" id="country" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Pais</label>
                        <mdb-error *ngIf="country.invalid && (country.dirty || country.touched)" style="color: red;">Pais ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="country.valid && (country.dirty || country.touched)" style="color: green;">Pais ingresado es valido</mdb-success>
                    </div>

                </div>



                <div class="modal-footer modals-footer pt-3">
                    <button type="button" mdbBtn class="bwaves-light button-cancel-modals" data-dismiss="modal"
                        (click)="modalAddCompany.hide()" mdbWavesEffect>Cancelar</button>
                    <button type="submit" mdbBtn class="waves-light button-accept-modals"
                        mdbWavesEffect>Aceptar</button>
                </div>
            </form>


        </div>
    </div>
</div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
    (reponseStateModalAccept)="onResponseModalAlert($event)"
    (reponsDeleteObjectAlert)="onReponseDeleteObjectAlert($event)">
</app-modal-accept>

<ngx-spinner bdColor = "rgba(3,128,128,0.7)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>
