import { CompaniesService } from 'src/app/services/companies.service';

import { User } from './../../models/user';
import { Company } from './../../models/backoffice/company';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { Project } from 'src/app/models/project';
import { ProjectsService } from './../../services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Unit } from 'src/app/models/unit';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit, OnDestroy {

  @ViewChild('modalAddCompany') modalAddCompany: ModalDirective;

  constructor( private projectsService: ProjectsService, private usersService: UsersService, private companiesService: CompaniesService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  units: Unit[] = [];

  projectsSubscription: Subscription;

  stateCompany: string = 'create'

  stateModal: boolean = false;
  stateModalAlert: boolean = false;
  modalInfo: ModalInfo = new ModalInfo();

  headElements = ['N°', 'Nombre', 'Compañia', 'Estado', 'Herramientas'];
  headTable = ['name', 'companyName', 'state', 'tools'];
  colspanLarge: number = 6;

  projectForm: FormGroup;

  states = [
    { value: true, label: 'Activo' },
    { value: false, label: 'Inactivo' },
  ];


  ngOnDestroy(){

    this.projectsSubscription.unsubscribe();
  }
  selectControl = new FormControl(['1']);

  ngOnInit(): void {

    this.spinner.show();

    this.resetForm();
    this.getUnits();
    this.getCompanies();
  
  }

  resetForm(){

    // this.selectState = new FormControl(null);

    this.projectForm = this.formBuilder.group({
      name: [{ value: null, disabled: false }, Validators.required],
      // location: [{ value: null, disabled: false }, Validators.required],
      active: [{ value: null, disabled: false }, Validators.required],
      company: [{ value: null, disabled: false }, Validators.required],
      users: [{ value: null, disabled: false }],

    });
  }
  

  reponseStateModal($event){
    this.stateModal = false;
  }


  submitCompany(){

    console.log('SUBMIT COMPANY');

    if(this.projectForm.invalid){

      this.stateModalAlert = true;  
      this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
      this.modalInfo.type = 'error';
      return
    }

    if(this.stateCompany == 'create'){
      this.createCompany();
    }else{
      this.updateCompany(this.companySelected.uid);
    }
    this.modalAddCompany.hide();

  }

  getUnits(){

    this.projectsSubscription = this.projectsService.getUnits( ).subscribe({
      next: (resp) => { 
         this.units = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModal = true;
        this.spinner.hide();
      }
    });

  }

  createCompany(){
  
    // this.projectForm.value.active = this.activeSelected;

    this.projectsService.createProject( this.projectForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia creada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getUnits();
    }
    });

  }

  updateCompany(id: string){
  
    this.projectsService.updateProject( id, this.projectForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia actualizada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getUnits();
    }
    });
  }

  deleteCompany(id: string){
  
    this.projectsService.deleteProject( id ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia eliminada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      console.log('Received an error: ' + err)
      this.stateModalAlert = true;  
      this.modalInfo.messageModal = err.error.msg;
      this.modalInfo.type = 'error' 
    
    },
    complete: () => {  
        console.log('Completed');
        this.getUnits();
    }
    });

  }

  showAddCompany(){

    console.log('SHOW MODAL');
    this.resetForm();
    this.users = [];
    this.stateCompany = 'create';
    this.modalAddCompany.show();
  }

  onResponseModalAlert($event){

    this.stateModalAlert = $event;
  }

  companySelected: Company;
  onreponsDeleteObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateModalAlert = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.name;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateCompany = 'update';
    this.modalAddCompany.show();

    // this.selectState = new FormControl($event.active);
    //this.getUsersByCompany($event.uid);
    this.projectForm.get('name').setValue($event.name);
    this.projectForm.get('active').setValue($event.active);
    this.projectForm.get('company').setValue($event.companyId);
    this.projectForm.get('users').setValue($event.users);

    // this.updateCompany(this.companySelected.uid);
  }

  onReponseDeleteObjectAlert($event){
    console.log('COMPANY: '+this.companySelected.uid);
    this.deleteCompany(this.companySelected.uid);
  }


  get name() {
    return this.projectForm.get('name');
  }

  // get location() {
  //   return this.projectForm.get('location');
  // }

  get active() {
    return this.projectForm.get('active');
  }

  get company() {
    return this.projectForm.get('company');
  }

  usersSubscription: Subscription;
  users: User[] = [];

  getUsersByCompany( id: string){

    this.usersSubscription = this.usersService.getUsersByCompany( id ).subscribe({
      next: (resp) => { 
         this.users = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModal = true;
        this.spinner.hide();
      }
    });

  }

  companies: Company[] = [];
  companiesSubscription: Subscription;
  getCompanies(){

    this.companiesSubscription = this.companiesService.getCompanies( ).subscribe({
      next: (resp) => { 
         this.companies = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); this.stateModal = true;}
    });

  }

  changeCompany(event){

    console.log('EVENT CHANGE: '+event);
    this.getUsersByCompany(event);
  }
  

}

