
import { Component, OnInit } from '@angular/core';
import { Mine } from 'src/app/models/mine';
import { MinesService } from './../../services/mines.service';

@Component({
  selector: 'app-mines',
  templateUrl: './mines.component.html',
  styleUrls: ['./mines.component.scss']
})
export class MinesComponent implements OnInit {

  platforms: Mine[] = [];
  backoffice$: any;

  constructor( private minesService: MinesService) { }

  ngOnInit(): void {

    this.backoffice$ =this.minesService.getMines( ).subscribe({
      next: (resp) => { 
        this.platforms = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); }
    });
  
  }

  verProducto( $event ){
    
  }
  

}
