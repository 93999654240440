
  
  <!--Main Layout-->
  <main>
    <div class="container-fluid mt-5">
      <h2>Advanced Double Navigation with fixed SideNav & fixed Navbar:</h2>
      <br>
      <h5>1. Hidden side menu. Click "hamburger" icon in the top left corner to open it.</h5>
      <h5>2. Fixed navbar. It will always stay visible on the top, even when you scroll down.</h5>
      <div style="height: 2000px"></div>
    </div>
  </main>
  <!--/Main layout-->