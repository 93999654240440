import { Gis } from './../../models/gis';

import { BackofficeService } from './../../services/backoffice.service';
import { Platform } from './../../models/platform';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-homeback',
  templateUrl: './homeback.component.html',
  styleUrls: ['./homeback.component.scss']
})
export class HomebackComponent implements OnInit {

  constructor(private router: Router, private backofficeService: BackofficeService, private spinner: NgxSpinnerService) { }


  backoffice: Gis[] = [];
  backofficeSubscription: Subscription;
  
  ngOnInit(): void {

    this.spinner.show();
    this.getBackoffice();
   
  }

  getBackoffice(){

      this.backofficeSubscription =this.backofficeService.getBackoffice( ).subscribe({
        next: (resp) => { 
           this.backoffice = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { 
          console.log('Completed');
          this.spinner.hide();
       }
      });     
  }
  
  }
  