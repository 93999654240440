


<div class="px-0 mx-0 container-fluid">
    <!-- <app-navbar></app-navbar> -->
    <app-navbar></app-navbar>
    <!-- <mdb-breadcrumb class="breadcrumbs">
        <mdb-breadcrumb-item routerLink="/rep/units">Unidades</mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/rep/servicesUnit">Servicios</mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/rep/preview">Módulos</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="active">Visualización</mdb-breadcrumb-item>
      </mdb-breadcrumb> -->
    <div class="main-view2">
        <div class="d-flex justify-content-between">
            <mdb-breadcrumb *ngIf="user.role == 'Admin' || user.role == 'Trabajador EMT'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
                <mdb-breadcrumb-item class="breadcrumbs-disabled" routerLink="/rep/companies"><i class="fa-solid fa-house mr-1"></i>Compañias</mdb-breadcrumb-item>
                <mdb-breadcrumb-item routerLink="/rep/unitsEmt" class="breadcrumbs-disabled">Unidades</mdb-breadcrumb-item>
                <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
                <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
                <mdb-breadcrumb-item class="breadcrumbs-enabled">Visualización</mdb-breadcrumb-item>
            </mdb-breadcrumb>
    
            <mdb-breadcrumb *ngIf="user.role == 'Cliente'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
                <mdb-breadcrumb-item routerLink="/rep/units" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Unidades</mdb-breadcrumb-item>
                <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
                <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
                <mdb-breadcrumb-item class="breadcrumbs-enabled">Visualización</mdb-breadcrumb-item>
            </mdb-breadcrumb>
            <!-- <app-navbar-preview></app-navbar-preview> -->
        
            <button type="button" mdbBtn class="bwaves-light button-cancel-modals" data-dismiss="modal" mdbWavesEffect>Descargar</button>
        </div>

        <router-outlet></router-outlet>

    </div>

</div>

