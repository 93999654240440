<div mdbModal #modalAccept="mdbModal" class="modal fade top modal-alert" id="modalAccept" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-notify" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <!-- [ngStyle]="{'background-color': ( modalInfo.type == 'error') ? '#D41920' : '#20A647'}" -->
      <div class="modal-header d-flex justify-content-center css-modal-header">
        <p class="text-head">Notificación</p>
      </div>
      <!--Body-->
      <div class="modal-body">
        <mdb-icon *ngIf="modalInfo.type == 'success'" far icon="check-circle" size="4x" class="green-text animated fadeIn"></mdb-icon>
        <mdb-icon *ngIf="modalInfo.type == 'error'" far icon="times-circle" size="4x" class="red-text animated fadeIn"></mdb-icon>
        <mdb-icon *ngIf="modalInfo.type == 'warning'" fas  icon="exclamation-triangle" size="4x" class="amber-text animated fadeIn"></mdb-icon>
        <p class="pt-3" style="color: black; font-size: 20px;">{{ modalInfo.messageModal }}</p>
      </div>
      <!--Footer-->
      <div *ngIf="modalInfo.type == 'success' || modalInfo.type =='error'" class="modal-footer justify-content-center">
          <button type="button" mdbBtn class="waves-light button-accept-modals"  (click)="modalAccept.hide()"
              mdbWavesEffect>Aceptar
          </button>          
      </div>

      <div *ngIf="modalInfo.type == 'warning'" class="modal-footer justify-content-end">
        <button type="button" mdbBtn class="waves-light button-cancel-modals"  (click)="modalAccept.hide()"
            mdbWavesEffect>Cancelar
        </button>   
        <button type="button" mdbBtn class="waves-light button-accept-modals"  (click)="deleteObject()"
            mdbWavesEffect>Aceptar
        </button>          
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


