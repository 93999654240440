import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-navbarback',
  templateUrl: './navbarback.component.html',
  styleUrls: ['./navbarback.component.scss']
})
export class NavbarbackComponent implements OnInit {

  user: User;
  constructor( private router: Router) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logout(){

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigateByUrl('/login');
  }

  goPlatform(){

    this.router.navigateByUrl('/rep/companies');
  }

  goPreview(){}
}
