
import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/backoffice/project';
import { User } from 'src/app/models/user';
import { ProjectsService } from './../../services/projects.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-units-emt',
  templateUrl: './units-emt.component.html',
  styleUrls: ['./units-emt.component.scss']
})
export class UnitsEmtComponent implements OnInit {

  projects: Project[] = [];
  backoffice$: any;
  user: User;

  company: string;
  companyId: string;
  companyName: string;
  
  constructor( private projectService: ProjectsService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user') || '');
    this.companyId = localStorage.getItem('company') || '';
    this.companyName = localStorage.getItem('companyName') || '';

    this.spinner.show();
    this.getUnitsByUser();
    console.log('USER: '+this.user.uid);
  
    this.company = this.user.company.name;
  
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
  }

  getUnitsByUser(){

    this.backoffice$ =this.projectService.getUnitsByUserAndCompany( this.user.uid, this.companyId ).subscribe({
      next: (resp) => { 
        this.projects = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.spinner.hide();
      }
    });
  }

}
