import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { ModalInfo } from 'src/app/models/modal-info';
import { CustomValidators } from 'src/app/providers/custom-validators';


@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  // recoverForm: FormGroup;
  recoverForm: any;
  token: string;

  // messageModal: string;
  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  // modalInfo = {
  //   messageModal: '',
  //   type: ''
  // }

  constructor(public formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private userService: UsersService) {

    this.token = this.route.snapshot.paramMap.get('token');  //a las rutas
    console.log('EL TOKEN: '+this.token);

  }

  public user: User[] = [];

  ngOnInit(): void {

    this.recoverForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6)]],
      password1: [null, [Validators.required, Validators.minLength(6)]],
    });

    CustomValidators.mustMatch('password', 'password1') // insert here

    // this.userService.getUsers().subscribe(users => {
    //   this.users = users;
    // });

  }

  // samePass(){

  //   if(this.recoverForm.value.password != this.recoverForm.value.password1 ){
      
  //     this.stateModal = true;  
  //     this.messageModal = 'Debe completar los campos correspondientes'
  //     return;
        
  //   }
  // }

  // getUsers(){
  //   this.userService.getUsers().subscribe(users => {
  //   this.users = users;
  //   console.log('resp: '+this.users[0].name1);
  // });
// }

recover() {

  // this.userService.recover( this.recoverForm.value, this.token ).subscribe(users => {
  //   this.users = users;
  // });
  if (this.recoverForm.invalid) {
    console.log('FORMULARIO INVALIDO2');
    this.stateModal = true;
    this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
    this.modalInfo.type = 'error'
    return;
  }

  if(this.recoverForm.value.password != this.recoverForm.value.password1 ){
      
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Las contraseñas deben ser iguales';
    this.modalInfo.type = 'error'
    return;
      
  }

  this.userService.recover( this.recoverForm.value, this.token ).subscribe({
    next: (resp) =>{ this.user = resp; 
        console.log('Response: ' + resp.ok);
        this.stateModal = true;  
        this.modalInfo.messageModal = resp.msg;
        this.modalInfo.type = 'success'
        this.user = resp; 
    },
    error: (err) => { 
      this.stateModal = true;  
      this.modalInfo.messageModal = err.error.msg;
      this.modalInfo.type = 'error'
      console.log('Received an error: ' + err)},
    complete: () => { 
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 2000);
        console.log('Completed'); }
  });
  
}

onResponseNormal(event){
  this.stateModal = false;
  console.log('RESPONSE MINE NORMAL');
}


returnLogin(){

  this.router.navigateByUrl('/login');

}


  get password1() { return this.recoverForm.get('password1'); }
  get password() { return this.recoverForm.get('password'); }
}