import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Monitoring } from '../models/monitoring';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor( private router: Router, private http: HttpClient ) { }

  getMonitoring(): Observable<Monitoring[]>{
    const url = `${ base_url }/monitoring`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }


}
