import { MonitoringService } from './../../services/monitoring.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Monitoring } from 'src/app/models/monitoring';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

  constructor( private router: Router, private monitoringServices: MonitoringService ) { }

  platforms: Monitoring[] = [];
    Monitoring$: any;
  
    ngOnInit(): void {
  
      this.Monitoring$ =this.monitoringServices.getMonitoring(  ).subscribe({
        next: (resp) => { 
           this.platforms = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { console.log('Completed'); }
      });
    
    }

    verProducto( idx: number ){
      console.log('ID PROJECT: '+idx);
      this.router.navigate( ['home',idx] );
    }
    
}
