import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() platform: any = {};
  @Input() index: any;


  @Output() productoSeleccionado: EventEmitter<number>;


  constructor(private router: Router) {
    this.productoSeleccionado = new EventEmitter();
  }

  ngOnInit() {
  }

  // edit( id: any ) {
  //   // console.log(  this.index );
  //   this.router.navigate( ['/emt', id] );

  //   // this.heroeSeleccionado.emit( this.index );
  // }

  // activity( proyecto_id: any, nombre_proyecto: any ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/admin'] );
    
  // }

  // users( proyecto_id: any, nombre_proyecto: any  ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/assign'] );

  // }

  // seeMine( idx:number ){
  //   this.router.navigate( ['/home'] );
  // }

  linkSection(){

    // console.log('URL: '+this.platform.url);
    // console.log('URL: '+this.platform.type);

    // window.location.href= this.platform.url;

    // if(this.platform.type == 'platform'){
    //   window.location.href= this.platform.url;
    // }else{
    //   this.router.navigateByUrl(this.platform.url);
    // }

  }

}
