import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../models/user';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoginForm } from '../interfaces/login-form';
import { ResendLogin } from '../interfaces/resend-form';
import { RecoverLogin } from '../interfaces/recover-login';

import {JwtHelperService} from '@auth0/angular-jwt';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { }

  // getUsers(): Observable<User[]>{
  //   const url = `${ base_url }/users`;
  //   return this.http.get( url )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: User[] }) => resp.objects )
  //             );
  // }

  getUsers(  ): Observable<User[]>{
    const url = `${ base_url }/users`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: User[] }) =>
                resp.objects.map( data => {
                  return {
                    uid: data.uid || null,
                    name1: data.name1 || null,
                    last_name1: data.last_name1 || null,
                    last_name2: data.last_name2 || null,
                    company: data.company || null,
                    role: data.role || null,
                    email: data.email || null,
                    active: data.active || null,
                    state: data.active ? 'Activo' : 'Inactivo',
                    companyName: data.company ? data.company.name || null : null,
                    companyId: data.company ? data.company._id || null : null,
                    // users: data.users,
                    // type: data.type,
                  }
                })
              )
              )
  }


  getUsersByCompany( id: string ): Observable<User[]>{
    const url = `${ base_url }/users/company/${id}`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: User[] }) =>
                resp.objects.map( data => {
                  return {
                    uid: data.uid,
                    name1: data.name1,
                    last_name1: data.last_name1,
                    last_name2: data.last_name2,
                    company: data.company,
                    role: data.role,
                    email: data.email,
                    active: data.active,
                    state: data.active ? 'Activo' : 'Inactivo',
                    companyName: data.company.name,
                    companyId: data.company._id,
                    // users: data.users,
                    // type: data.type,
                  }
                })
              )
              )
  }

  getUsersByUnit( id: string ): Observable<User[]>{
    const url = `${ base_url }/users/unit/${id}`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: User[] }) => resp.objects ),
              );
  }

  

  createUser( user: User){
    const url = `${ base_url }/auth/register`;
    return this.http.post( url, user )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  
  updateUser( id: string, user: User){
    const url = `${ base_url }/users/${id}`;
    return this.http.put( url, user )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  deleteUser( id: string){
    const url = `${ base_url }/users/${id}`;
    return this.http.delete( url )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }


  login( formData: LoginForm ) {
    
    return this.http.post(`${ base_url }/auth/login`, formData )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                    console.log(resp.user);
                    this.guardarLocalStorage( resp.token, resp.user );
                  })
                );
  }

  guardarLocalStorage( token: string, user: any ) {

    localStorage.setItem('token', token );
    localStorage.setItem('user', JSON.stringify(user) );
    localStorage.setItem('role', user.role );


    // localStorage.setItem('menu', JSON.stringify(menu) );

  }

  
  validarToken(): Observable<boolean> {

    const token = localStorage.getItem('token') || '';

    return this.http.get(`${ base_url }/auth/renew`, {
      headers: {
        'x-token': token
      }
    }).pipe(
      tap( (resp: any) => {
        localStorage.setItem('token', resp.token.token );
      }),
      map( resp => true),
      catchError( error => of(false) )
    );

  }


  resend( formData: ResendLogin ) {
    
    console.log('EMAIL: '+formData.email);
    return this.http.post(`${ base_url }/recover/resend`, formData )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                  })
                );
  }

  recover( formData: RecoverLogin, token: string ) {
    
    const body = { 
      "data": formData,
      "token": token
    }
    
    return this.http.post(`${ base_url }/recover/recover`, body )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                  })
                );
  }

  roleAs: string;

  getRole() {
    this.roleAs = localStorage.getItem('role');
    return this.roleAs;
  }


  expiredToken( ){

    const token = localStorage.getItem('token');

    if (this.jwtHelper.isTokenExpired(token)) {
      // token expired 
      return false
    } else {
      // token valid
      return true
    }
  }



}

