
<main class="background-preview animated fadeIn slow">
    <div class="main-view">
        <mdb-breadcrumb *ngIf="user.role == 'Admin' || user.role == 'Trabajador EMT'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item class="breadcrumbs-disabled" routerLink="/rep/companies"><i class="fa-solid fa-house mr-1"></i>Compañias</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/unitsEmt" class="breadcrumbs-disabled">Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Reportabilidad</mdb-breadcrumb-item>
        </mdb-breadcrumb>

        <mdb-breadcrumb *ngIf="user.role == 'Cliente' " customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item routerLink="/rep/units" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Reportabilidad</mdb-breadcrumb-item>
        </mdb-breadcrumb>

        <div class="component-div">
            <div class="image-container">
                <img src="/assets/report.svg" width="100px" alt="image">
                <h5 class="subtitle-project p-0 m-0 pb-2">{{ serviceUnit.name | titlecase }}</h5>
                <h1 class="text-center title-image p-0 m-0">Reportabilidad</h1>
            </div>
    
            <div class="card-columns container pb-5 card-config">
    
                <app-cards [objectx]="objectx" [index]="i+1" *ngFor="let objectx of sections; let i=index "></app-cards>
    
            </div>
        </div>
    </div>
</main>

<ngx-spinner bdColor = "rgba(3,128,128,0.7)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>