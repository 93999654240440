import { CompaniesService } from './../../services/companies.service';

import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/backoffice/project';
import { User } from 'src/app/models/user';
import { ProjectsService } from './../../services/projects.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Company } from 'src/app/models/backoffice/company';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  projects: Company[] = [];
  backoffice$: any;
  user: User;

  company: string;
  constructor( private projectService: ProjectsService, private companiesService: CompaniesService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user') || '');
    this.spinner.show();
    this.getUnitsByUser();
    console.log('USER: '+this.user.uid);
  
    this.company = this.user.company.name;
  
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
  }

  getUnitsByUser(){

    this.backoffice$ =this.companiesService.getCompanies( ).subscribe({
      next: (resp) => { 
        this.projects = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.spinner.hide();
      }
    });
  }


  

}
 
