import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';

import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';


@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss']
})
export class ResendComponent implements OnInit {

  @ViewChild('modalAccept') modalAccept: ModalDirective;

  // resendForm: FormGroup;
  resendForm: any;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;


  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UsersService) {
  }

  public users: User[] = [];

  ngOnInit(): void {

    this.resendForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });

  }


resend() {


  if (this.resendForm.invalid) {
    console.log('FORMULARIO INVALIDO');
    this.stateModal = true;
    this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
    this.modalInfo.type = 'error'
    // this.modalAccept.show(); 
    return;
  }


  this.resendEmail();
  // this.modalAccept.show(); 

}

resendEmail(){
  
  console.log('USER: '+this.resendForm.value.email);

  this.userService.resend( this.resendForm.value ).subscribe({
    next: (resp) =>{  
      console.log('Response: ' + resp.ok);
      this.stateModal = true;  
      this.modalInfo.messageModal = resp.msg;
      this.modalInfo.type = 'success'
  },
  error: (err) => { 
    this.stateModal = true;  
    this.modalInfo.messageModal = err.error.msg;
    this.modalInfo.type = 'error'
    console.log('Received an error: ' + err)},
  complete: () => { 
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 3000);
      console.log('Completed'); }
});
}

onResponseNormal(event){
  this.stateModal = false;
  console.log('RESPONSE MINE NORMAL');
}


returnLogin(){

  this.router.navigateByUrl('/login');

}

  get email() { return this.resendForm.get('email'); }
  get password() { return this.resendForm.get('password'); }
}