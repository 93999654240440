import { ServicesemtComponent } from './servicesemt/servicesemt.component';
import { UsersComponent } from './users/users.component';
import { PlatformsbackComponent } from './platformsback/platformsback.component';
import { ProjectsComponent } from './projects/projects.component';
import { CompaniesComponent } from './companies/companies.component';
import { HomebackComponent } from './homeback/homeback.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackofficeComponent } from './backoffice.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [

  // { path: 'back', component: HomebackComponent},
  { 
    path: 'backrep', 
    component: BackofficeComponent,
    canActivate: [ AuthGuard ],
    data: {
      role: ['Admin']
    },
    children: [
      // { path: '', component: HomeComponent},
      { path: 'home', component: HomebackComponent},
      { path: 'companies', component: CompaniesComponent},
      { path: 'users', component: UsersComponent},
      { path: 'projects', component: ProjectsComponent},
      { path: 'platforms', component: PlatformsbackComponent},
      { path: 'services', component: ServicesemtComponent},

      // { path: 'reports', component: ReportsComponent},
      // { path: 'gis', component: GisComponent},
      // { path: 'applications', component: ApplicationsComponent},
      // { path: 'monitoring', component: MonitoringComponent},
      // { path: 'elerts', component: AlertsComponent},

    ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
