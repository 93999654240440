import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-modal-accept',
  templateUrl: './modal-accept.component.html',
  styleUrls: ['./modal-accept.component.scss']
})
export class ModalAcceptComponent implements OnInit, OnChanges {

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Output() reponseStateModalAccept = new EventEmitter();
  @Output() reponsDeleteObjectAlert = new EventEmitter();

  @ViewChild('modalAccept') modalAccept: ModalDirective;

  constructor() { }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);


    if(this.stateModal == true){
      console.log('MESSAGE IN ONCHANGES');
      this.modalAccept.show();
      
      setTimeout(() => {
        this.reponseStateModalAccept.emit(false);
      }, 500);
    }
  }


  ngOnInit(): void {
  }

  deleteObject(){

    this.reponsDeleteObjectAlert.emit(true);
    this.modalAccept.hide();
  }

}
