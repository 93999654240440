import { Platform } from './../models/platform';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Alerts } from '../models/alerts'

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  // getPlatforms(): Observable<Platform[]>{
  //   const url = `${ base_url }/platforms`;
  //   return this.http.get( url )
  //             .pipe(
  //               tap( resp => console.log('platforms: '+resp)),
  //               map( (resp: {ok: boolean, platforms: Platform[] }) => resp.platforms ),
  //             );
  // }

  getAlerts( ): Observable<Alerts[]>{
    const url = `${ base_url }/alerts`;
    return this.http.get( url )
              .pipe(
                tap( resp => console.log('alerts: '+resp)),
                map( (resp: {ok: boolean, objects: Alerts[] }) => resp.objects ),
              );
  }

  getReportsByDate(date: string): Observable<Alerts[]>{
    const url = `${ base_url }/alerts/date/${date}`;
    return this.http.get( url )
              .pipe(
                tap( resp => console.log('alerts: '+resp)),
                map( (resp: {ok: boolean, objects: Alerts[] }) => resp.objects ),
              );
  }

  getFileByUrl(urlFile: string){
    const url = `${ base_url }/files/${ urlFile }`;
    return this.http.get( url, {responseType: 'blob'} );
  }

}