// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: 'http://34.125.65.254/api'
  //base_url: 'https://m2d.emt.cl/api',  //f2
  base_url: 'https://p2iggestion.emt.cl/api',  //funciona activar
  // base_url: 'https://apimydatagcp.emt.cl/api'
  //base_url: 'http://localhost:3007/api',
  base_cmp: 'https://consultascmp.emt.cl'
  //base_cmp: 'http://10.10.10.18:3080'
  // base_url: 'https://apimydatagcp.emt.cl/api'
  //  base_url: 'http://localhost:3007/api'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
