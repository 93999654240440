import { UsersService } from './../services/users.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


    constructor(private usersServices: UsersService, private router: Router) { }
    
      canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let url: string = state.url;
        return this.checkUserLogin(next, url);
      }
      canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
      }
      canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
      }
      canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return true;
      }
    
      checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.usersServices.validarToken()) {
          const userRole = this.usersServices.getRole();
          const tokenValidate = this.usersServices.expiredToken();
          console.log('USER ROLE: '+userRole);
          console.log('TOKEN VALIDATE: '+tokenValidate);
          console.log('ROUTE ROLE'+route.data.role);
          if (route.data.role && route.data.role.indexOf(userRole) === -1 )  {
            this.router.navigate(['/login']);
            return false;
          }
          if (userRole && tokenValidate == true )  {

            console.log('return '+true);
            return true;
          }
          this.router.navigate(['/login']);
          console.log('return '+false);
          return false;
        }
    
        this.router.navigate(['/login']);
        return false;
      }
      
    
  // canActivate(
    
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot) {

  //     return this.usersServices.validarToken()
  //       .pipe(
  //         tap( estaAutenticado =>  {
  //           console.log('ESTA AUTENTIFICADO');
  //           if ( !estaAutenticado ) {
  //             this.router.navigateByUrl('/login');
  //           }
  //         })
  //       );
  // }
  
}
