
import { MinesComponent } from './mines/mines.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { ApplicationsComponent } from './applications/applications.component';
import { GisComponent } from './gis/gis.component';
import { ReportsComponent } from './reports/reports.component';
import { PreviewComponent } from './preview/preview.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { ServicesUnitComponent } from './services-unit/services-unit.component';
import { CompaniesComponent } from './companies/companies.component';
import { UnitsEmtComponent } from './units-emt/units-emt.component';
import { DownloadComponent } from './download/download.component';


const routes: Routes = [

  // { path: 'preview', component: PreviewComponent, canActivate: [ AuthGuard ]},
  // { path: 'projects', component: ProjectsComponent, canActivate: [ AuthGuard ]},
  { 
    path: 'rep', 
    component: PagesComponent,
    canActivate: [ AuthGuard ],
    data: {
      role: ['Admin', 'Cliente', 'Trabajador EMT']
    },
    children: [
      // { path: '', component: HomeComponent},
      { path: 'home', component: HomeComponent},
      { path: 'reports', component: ReportsComponent},
      { path: 'gis', component: GisComponent},
      { path: 'preview', component: PreviewComponent},
      { path: 'companies', component: CompaniesComponent},
      { path: 'units', component: ProjectsComponent},
      { path: 'servicesUnit', component: ServicesUnitComponent},
      { path: 'unitsEmt', component: UnitsEmtComponent},
      { path: 'download', component: DownloadComponent},

      // { path: 'applications', component: ApplicationsComponent},
      // { path: 'monitoring', component: MonitoringComponent},
      // { path: 'elerts', component: AlertsComponent},

    ]

  },
  { 
    path: 'reports', 
    component: PagesComponent,
    // canActivate: [ AuthGuard ],
    children: [
      // { path: '', component: HomeComponent},
      { path: 'alerts', component: AlertsComponent},

    ]
  },



    ];
    // canActivate: [ AuthGuard ],
    // canLoad: [ AuthGuard ],
  @NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
