
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Company } from '../models/backoffice/company';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor( private router: Router, private http: HttpClient ) { }

  getCompanies(): Observable<Company[]>{
    const url = `${ base_url }/companies`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Company[] }) => resp.objects )
              );
  }

  createCompany( company: Company){
    const url = `${ base_url }/companies`;
    return this.http.post( url, company )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  
  updateCompany( id: string, company: Company){
    const url = `${ base_url }/companies/${id}`;
    return this.http.put( url, company )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  deleteCompany( id: string){
    const url = `${ base_url }/companies/${id}`;
    return this.http.delete( url )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

}