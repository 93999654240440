import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Gis } from '../models/gis';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

const base_url = environment.base_url;
const base_cmp = environment.base_cmp;

@Injectable({
  providedIn: 'root'
})
export class DowwnloadService {

  constructor( private router: Router, private http: HttpClient ) { }

  getCrackmeter(){
    const url = `${ base_cmp }/data/registros/crackmeter`;
    return this.http.get( url );
  }

  getTypes(){
    const url = `${ base_cmp }/data/sensor_types/`;
    return this.http.get( url );
  }

  getChannels(){
    const url = `${ base_cmp }/data/channels/`;
    return this.http.get( url );
  }

  getData(id: string, type: string){
    const url = `${ base_cmp }/data/registros/${type}/${id}`;
    return this.http.get( url );
  }
}