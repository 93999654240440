
<!--Main Layout-->

<main class="background-preview animated fadeIn fast">
    <div class="main-view">
        <mdb-breadcrumb customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item routerLink="/backrep/home" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Backoffice</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Plataformas</mdb-breadcrumb-item>
        </mdb-breadcrumb>
        <div class="container">
            <div class="icon-preview pb-3">
                <img src="/assets/platforms.svg" width="70px" alt="image">
                <h1 class="text-center title-view pl-3 mx-0">Plataformas</h1>
            </div>
            <div class="table-div mt-2 pb-3 z-depth-5">
                <div class="table-header">
                    <div style="width: 45px; height: 45px;"></div>
                    <h2 class="title-header">Registro de Plataformas</h2>
                    <button mdbBtn type="button" class="button-add" (click)="showAddCompany()" mdbWavesEffect>
                        <mdb-icon fas icon="plus-circle" floating="true" size="2x"></mdb-icon>
                      </button>
                </div>
                <div class="table-body container">
                    <app-table [data]="platforms" [stateModal]="stateModal" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
                     (reponseStateModalExclusion)="reponseStateModal($event)"
                     (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
                     (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table>
                </div>
            </div>
        </div>

    </div>
</main>
<!--/Main layout-->

<div mdbModal #modalAddCompany="mdbModal" class="modal fade right" id="modalCgm" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
<div class="modal-dialog modalUser" role="document">
    <div class="modal-content">
        <div class="modal-header head-modals">
            <h2 class="modal-title w-100 title-modals" id="myModalLabel">Agregar Plataforma</h2>
            <button type="button" class="close x-modals" data-dismiss="modal" aria-label="Close"
                (click)="modalAddCompany.hide()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body modals-body container">
            <form class="form-horizontal p-t-20 text-modals" [formGroup]="platformForm"
                (submit)="submitCompany()">

                <div class="row">
                    <!-- <div class="col-md-6 md-form md-outline">
                        <input id="form-lg" formControlName="name" id="name" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Nombre</label>
                        <mdb-error *ngIf="name.invalid && (name.dirty || name.touched)" style="color: red;" >Nombre ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="name.valid && (name.dirty || name.touched)" style="color: green;" class="pl-3">Nombre ingresado es valido</mdb-success>
                    </div> -->
                    <!-- <div class="col-6 md-form md-outline">
                        <input id="form-lg" formControlName="project" id="project" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Proyecto</label>
                        <mdb-error *ngIf="project.invalid && (project.dirty || project.touched)" style="color: red;" class="pl-3">Proyecto ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="project.valid && (project.dirty || project.touched)" style="color: green;" class="pl-3">Proyecto ingresado es valido</mdb-success>
                    </div> -->
                    <!-- <div class="col-6 md-form md-outline">
                        <input id="form-lg" formControlName="active" id="active" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Estado</label>
                        <mdb-error *ngIf="active.invalid && (active.dirty || active.touched)" style="color: red;" class="pl-3">Estado ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="active.valid && (active.dirty || active.touched)" style="color: green;" class="pl-3">Estado ingresado es valido</mdb-success>
                    </div> -->

                    <div class="col-md-6 md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Nombre" label="Nombre" [formControlName]="'name'" (ngModelChange)="changedType($event)">
                            <mdb-select-option *ngFor="let option of names" [value]="option.value">{{ option.label }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class="col-md-6 md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Servicio" label="Servicio" [formControlName]="'service'">
                            <mdb-select-option *ngFor="let option of servicesUnit" [value]="option.uid">{{ option.name + ' - ' + option.unitName }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class="col-12 md-form md-outline" *ngIf="platformType == 'Visualización'">
                        <input id="form-lg" formControlName="url" id="url" class="form-control" type="text" mdbInput>
                        <label for="form-lg" class="pl-4">Url</label>
                        <mdb-error *ngIf="url.invalid && (url.dirty || url.touched)" style="color: red;" class="pl-3">Url ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="url.valid && (url.dirty || url.touched)" style="color: green;" class="pl-3">Url ingresado es valido</mdb-success>
                    </div>

                    <div class="col-md-6 md-form md-outline ml-2">
                        <mdb-checkbox [formControlName]="'download'">Descargar Datos</mdb-checkbox>
                    </div>


                </div>



                <div class="modal-footer modals-footer pt-3">
                    <button type="button" mdbBtn class="bwaves-light button-cancel-modals" data-dismiss="modal"
                        (click)="modalAddCompany.hide()" mdbWavesEffect>Cancelar</button>
                    <button type="submit" mdbBtn class="waves-light button-accept-modals"
                        mdbWavesEffect>Aceptar</button>
                </div>
            </form>


        </div>
    </div>
</div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
    (reponseStateModalAccept)="onResponseModalAlert($event)"
    (reponsDeleteObjectAlert)="onReponseDeleteObjectAlert($event)">
</app-modal-accept>

<ngx-spinner bdColor = "rgba(3,128,128,0.7)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>
