
<button type="button" mdbBtn class="bwaves-light button-cancel-modals mt-5" data-dismiss="modal"
(click)="openModal()" mdbWavesEffect>Descargar</button>
<p>download works!</p>
<p>{{ dataFilter | json }}</p>


<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
                  <div class="mx-auto px-4" style="background-color: white;">
                    <div class="row">
                                    
                      <!-- <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Sector" label="Sector" [formControlName]="'sector_faena_id'" (ngModelChange)="changedType($event)">
                            <mdb-select-option *ngFor="let option of sectorsMine" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->

                    <div class="col-6 md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Tipo" label="Tipo" [formControlName]="'type'">
                            <mdb-select-option *ngFor="let option of types" [value]="option.uid" (click)="changedType(option)">{{ option.name }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class="col-6 md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="Seleccionar Id" label="Tipo" [formControlName]="'channel'">
                          <mdb-select-option *ngFor="let option of channels" [value]="option.id" (click)="changedChannel(option)">{{ option.gid }}</mdb-select-option>
                      </mdb-select-2>
                    </div>


                      <!-- <div class="input-time md-form md-outline mt-5 col-md-6">
                        <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                          <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                            label="Fecha de inicio"  formControlName="fecha_inicio" required>
                          </mdb-date-picker>            
                        </div>
                      </div>    

                      <div class="input-time md-form md-outline mt-5 col-md-6">
                        <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                          <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                            label="Fecha de fin">
                          </mdb-date-picker>
                        </div>
                      </div>     -->
                    </div>

                  </div>         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="download()" mdbWavesEffect>Descargar</button>
            </div>
        </div>
    </div>
</div>

<!-- <app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept> -->

