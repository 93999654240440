import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewsRoutingModule } from './views-routing.module';
import { ViewsComponent } from './views.component';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbModule, IconsModule } from 'ng-uikit-pro-standard';


@NgModule({
  declarations: [
    ViewsComponent
  ],
  imports: [
    CommonModule,
    ViewsRoutingModule,
    SharedModule,
    BreadcrumbModule,
    IconsModule 
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ViewsModule { }
