import { ApplicationsService } from './../../services/applications.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Aplication } from 'src/app/models/aplication';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent {

 
  constructor( private router: Router, private applicationsService: ApplicationsService ) { }

  platforms: Aplication[] = [];
    applications$: any;
  
    ngOnInit(): void {
  
      this.applications$ =this.applicationsService.getApplications(  ).subscribe({
        next: (resp) => { 
           this.platforms = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { console.log('Completed'); }
      });
    
    }

    verProducto( idx: number ){
      console.log('ID PROJECT: '+idx);
      this.router.navigate( ['home',idx] );
    }
    
}
