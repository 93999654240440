import { BackofficeComponent } from './backoffice.component';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from '../components/components.module';
import { MDBBootstrapModulesPro, MdbSelectModule, ModalModule } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { DatepickerModule, WavesModule } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BackofficeRoutingModule } from './backoffice-routing.module';
import { HomeComponent } from './home/home.component';
import { HomebackComponent } from './homeback/homeback.component';
import { CompaniesComponent } from './companies/companies.component';
import { UsersComponent } from './users/users.component';
import { ProjectsComponent } from './projects/projects.component';
import { PlatformsbackComponent } from './platformsback/platformsback.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServicesemtComponent } from './servicesemt/servicesemt.component';

// import { JwtModule } from "@auth0/angular-jwt";

// export function tokenGetter() {
//   return localStorage.getItem("token");
// }

@NgModule({
  declarations: [
    HomeComponent,
    HomebackComponent,
    BackofficeComponent,
    CompaniesComponent,
    UsersComponent,
    ProjectsComponent,
    PlatformsbackComponent,
    ServicesemtComponent
  ],
  imports: [
    CommonModule,
    BackofficeRoutingModule,
    ComponentsModule,
    SharedModule,
    DatepickerModule,
    WavesModule,
    FormsModule,
    ReactiveFormsModule,
    MdbSelectModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: tokenGetter,
    //     // allowedDomains: ["example.com"],
    //     // disallowedRoutes: ["http://example.com/examplebadroute/"],
    //   },
    // }),
    ModalModule.forRoot(),
    MDBBootstrapModulesPro.forRoot()
  ],
  // providers: [MDBSpinningPreloader],
  // bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class BackofficeModule { }
