import { Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
// import { ModalDirective } from "angular-bootstrap-md";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('frame') frame: ModalDirective;

  // loginForm: FormGroup;
  loginForm: any;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  roleUser: string = '';
  version = '2.1';

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UsersService, private spinner: NgxSpinnerService) {
  }

  public user: User[] = [];

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });

    this.spinner.show();

    console.log('VERSION: '+this.version);
    // this.userService.getUsers().subscribe(users => {
    //   this.users = users;
    // });

  }

  // showAndHideModal() {
  //   this.demoBasic.show();

  //   // setTimeout(() => {
  //   //   this.demoBasic.hide();
  //   // }, 3000);
  // }


//   getUsers(){
//     this.userService.getUsers().subscribe(users => {
//     this.users = users;
//     console.log('resp: '+this.users[0].name1);
//   });
// }


login() {

  console.log('USER: '+this.loginForm.value.email);
  console.log('PASS: '+this.loginForm.value.password);

  // localStorage.setItem('mode', 'preview');

  if (this.loginForm.invalid) {
    console.log('FORMULARIO INVALIDO2');
    console.log('STATE MODAL: '+this.stateModal);
    this.stateModal = true;
    console.log('STATE MODAL: '+this.stateModal);
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
    this.modalInfo.type = 'error'
    // this.modalAccept.show(); 
    return;
  }

  this.userService.login( this.loginForm.value ).subscribe({
    next: (resp) =>{  
      this.user = resp.user;
      this.roleUser = resp.user.role;
      // console.log('ROLE USER: '+resp.user.role);
      // this.frame.show();
    },
    error: (err) => { 
      this.stateModal = true;  
      this.modalInfo.messageModal = err.error.msg;
      this.modalInfo.type = 'error'
      console.log('Received an error: ' + err)},
    complete: () => { 
      setTimeout(() => {
      console.log('ROLE USER: '+this.roleUser);
        if( this.roleUser == 'Trabajador EMT' || this.roleUser == 'Admin' ){
          this.router.navigateByUrl('/rep/companies');
        }else{
          this.router.navigateByUrl('/rep/units');
        }
      }, 1000);
        console.log('Completed'); }
  });

}


onResponseNormal(event){
  this.stateModal = false;
  console.log('RESPONSE MINE NORMAL');
}


resendPass(){

  this.router.navigateByUrl('/resend');

}

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
}