import { Subscription } from 'rxjs';
import { Platform } from './../../models/platform';
import { PlatformsService } from './../../services/platforms.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Section } from 'src/app/models/section';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceUnit } from 'src/app/models/service-unit';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  user: User;
  
  constructor(private router: Router, private platformsService: PlatformsService, private spinner: NgxSpinnerService) { }

  //   mines = [
  //     {
  //         "compania": "Antofagasta Minerals",
  //         "nombre": "Minera Los Pelambres",
  //         "ubicacion": "Salamanca",
  //         "uid": "6241df97f3a61e917be97083",
  //         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/pelambres.PNG?alt=media&token=de84b494-3095-4d0b-9839-80b3266f1847"
  //     },
  //     {
  //         "compania": "Antofagasta Minerals",
  //         "nombre": "Antucoya",
  //         "ubicacion": "Antofagasta",
  //         "uid": "6241dfa6f3a61e917be97085",
  //         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/antacuya.jpg?alt=media&token=c30d4fb8-722d-42df-bc86-cd9952322eea"
  //     },
  //     {
  //         "compania": "Antofagasta Minerals",
  //         "nombre": "Centinela",
  //         "ubicacion": "Antofagasta",
  //         "uid": "62435d7fbf8778358b0f880a",
  //         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/Centinela.jpg?alt=media&token=07b5d663-c7e7-4df2-9438-813cf64610e2"
  //     }
  // ];

  serviceUnit: ServiceUnit;
  sections: Section[] = [];
  sectionsSubscription: Subscription;
  
    ngOnInit(): void {
  
      this.spinner.show();
      this.serviceUnit = JSON.parse(localStorage.getItem('serviceUnit'));
      this.user = JSON.parse(localStorage.getItem('user') || '');
      this.getPlataforms();
    
    }

    getPlataforms(){

      this.sectionsSubscription =this.platformsService.getSectionsByPlatform( '6303b356ddf857948836f2a8' ).subscribe({
        next: (resp) => { 
           this.sections = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { 
          console.log('Completed');
          this.spinner.hide();
        }
      });

    }
  
    // verProducto( idx: number ){
    //   console.log('ID PROJECT: '+idx);
    //   this.router.navigate( ['home',idx] );
    // }
  
  }
  