import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-cards",
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.scss"],
})
export class CardsComponent implements OnInit {
  
  @Input() objectx: any = {};
  @Input() index: any;

  @Output() productoSeleccionado: EventEmitter<number>;

  constructor(private router: Router) {
    this.productoSeleccionado = new EventEmitter();
  }

  ngOnInit() {}

  // edit(id: any) {
  //   // console.log(  this.index );
  //   this.router.navigate(["/emt", id]);

  //   // this.heroeSeleccionado.emit( this.index );
  // }

  // activity(proyecto_id: any, nombre_proyecto: any) {
  //   localStorage.setItem("proyecto_id", proyecto_id);
  //   localStorage.setItem("nombre_proyecto", nombre_proyecto);
  //   this.router.navigate(["/home/admin"]);
  // }

  // users(proyecto_id: any, nombre_proyecto: any) {
  //   localStorage.setItem("proyecto_id", proyecto_id);
  //   localStorage.setItem("nombre_proyecto", nombre_proyecto);
  //   this.router.navigate(["/home/assign"]);
  // }

  // seeMine(idx: number) {
  //   this.router.navigate(["/home"]);
  // }

  linkSection() {
    console.log("URL: " + this.objectx.url);
    // console.log("URL: " + this.objectx.type);

    //Projects
    if(this.objectx.type == 'project'){
      localStorage.setItem("project", JSON.stringify(this.objectx));
      this.router.navigateByUrl('/rep/servicesUnit');
    } else if(this.objectx.type == 'platform'){
      // window.location.href= this.objectx.url;
      //this.router.navigateByUrl('/views/gis');
      this.router.navigateByUrl('/rep/gis');
      localStorage.setItem("gis", JSON.stringify(this.objectx));
    } else if(this.objectx.type == 'service'){
      // window.location.href= this.objectx.url;
      console.log("URL: " + this.objectx.url);
      console.log("URL: " + this.objectx.img);
      console.log("URL: " + this.objectx.uid);

      localStorage.setItem("serviceUnit", JSON.stringify(this.objectx));
      if(this.objectx.url){
        window.open(this.objectx.url,'_blank')
      }else{
        this.router.navigateByUrl('/rep/preview');
      }

      // this.router.navigateByUrl('/rep/preview');
    } else if(this.objectx.type == 'report'){
      // window.location.href= this.objectx.url;
      this.router.navigateByUrl('/rep/reports');
      localStorage.setItem("gis", this.objectx.url);
    } else if(this.objectx.type == 'company'){
      // window.location.href= this.objectx.url;
      this.router.navigateByUrl('/rep/unitsEmt');
      localStorage.setItem("company", this.objectx.uid);
      localStorage.setItem("companyName", this.objectx.name);
    } else{
      this.router.navigateByUrl(this.objectx.url);
    }

    // if(this.objectx.type == 'project'){
    //   localStorage.setItem("project", JSON.stringify(this.objectx));
    //   this.router.navigateByUrl('/preview');
    // } else if(this.objectx.type == 'platform'){
    //   window.location.href= this.objectx.url;
    // }else{
    //   this.router.navigateByUrl(this.objectx.url);
    // }
  }
}
