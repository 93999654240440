<main class="background-preview animated fadeIn slow">
    <div class="main-view">
        <mdb-breadcrumb *ngIf="user.role == 'Admin' || user.role == 'Trabajador EMT'" customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item class="breadcrumbs-disabled" routerLink="/rep/companies"><i class="fa-solid fa-house mr-1"></i>Compañias</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/unitsEmt" class="breadcrumbs-disabled">Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="active"class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/reports" class="breadcrumbs-disabled">Reportabilidad</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Alertas-Alarmas</mdb-breadcrumb-item>
        </mdb-breadcrumb>

        <mdb-breadcrumb *ngIf="user.role == 'Cliente' " customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item routerLink="/rep/units" class="breadcrumbs-disabled"><i class="fa-solid fa-house mr-1"></i>Unidades</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/servicesUnit" class="breadcrumbs-disabled">Servicios</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/preview" class="breadcrumbs-disabled">Módulos</mdb-breadcrumb-item>
            <mdb-breadcrumb-item routerLink="/rep/reports" class="breadcrumbs-disabled">Reportabilidad</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled">Alertas-Alarmas</mdb-breadcrumb-item>
        </mdb-breadcrumb>

        <div class="component-div">
            <div class="image-container">
                <img src="/assets/report.svg" width="70px" alt="image">
                <h5 class="subtitle-project p-0 m-0 pb-2">{{ serviceUnit.name | titlecase }}</h5>
                <h1 class="text-center title-image p-0 m-0">Alertas/Alarmas</h1>
            </div>
    
            <div class="container tabs-container pb-5">
    
                <!-- Nav Tabs -->
                <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3 teal darken-2'"  [contentClass]="'card'">
                    <!--Panel 1-->
                    <mdb-tab heading="Buscar Reporte">
                                <div class="inputs-container">
                                    <div class="row">
                                        <div class="col-md-4 md-form md-outline">
                                            <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" label="Fecha"
                                                class="input-calendar" [placeholder]="'Selecciona Fecha'"
                                                [(ngModel)]="dateCalendar" (ngModelChange)="getSelectedValue($event)" required>
                                            </mdb-date-picker>
                                        </div>
        
                                        <div class="col-md-4 md-form md-outline">
                                            <mdb-select-2 [outline]="true" placeholder="Seleccionar Reporte" label="Reporte" [(ngModel)]="dataSelect" (ngModelChange)="getSelectedValueReport($event)">
                                                <mdb-select-option *ngFor="let option of alerts" [value]="option.url">{{ option.name }}</mdb-select-option>
                                            </mdb-select-2>
                                        </div>
                                        <div class="col-md-4 md-form md-outline">
                                            <div class="button-container">
                                                <button mdbBtn type="button" class="download-button" rounded="false"
                                                (click)="downloadReport()" mdbWavesEffect>Descargar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </mdb-tab>
                    <!--Panel 2-->
                    <mdb-tab heading="Ultimos Reportes">
                        <div class="row">
                            <div class="col-12">
                                <div class="table-body container">
                                    <app-table-download [data]="alerts" [stateModal]="stateModal" [headElements]="headElements" 
                                    [headTable]="headTable" [colspanLarge]="colspanLarge"
                                    (reponseDownloadTable)="onreponseDownloadObjectTable($event)"></app-table-download>
                                </div>
                            </div>
                        </div>
                    </mdb-tab>
                    <!--Panel 3-->
                    <!-- <mdb-tab heading="Contact">
          <div class="row">
            <div class="col-12">
              <br>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                porro voluptate odit minima.
              </p>
            </div>
          </div>
        </mdb-tab> -->
                </mdb-tabset>
    
    
    
    
    
    
            </div>
        </div>
    </div>
</main>