import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {

  user: User;
  
  constructor() { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user') || '');
  }

}
