
import { Component, OnInit, ViewChild } from '@angular/core'; 
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { Company } from 'src/app/models/backoffice/company';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild('modalAddUser') modalAddUser: ModalDirective;

  constructor( private usersService: UsersService, private companiesService: CompaniesService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  // users: User[] = [];

  usersSubscription: Subscription;

  stateUser: string = 'create'

  stateModal: boolean = false;
  stateModalAlert: boolean = false;
  modalInfo: ModalInfo = new ModalInfo();

  headElements = ['N°', 'Nombre', 'Apellido', 'Compañia', 'Rol', 'Estado', 'Herramientas'];
  headTable = ['name1', 'last_name1', 'companyName', 'role', 'state', 'tools'];
  colspanLarge: number = 7;

  userForm: FormGroup;

  options = [
    { value: 'Admin', label: 'Administrador' },
    { value: 'Trabajador EMT', label: 'Trabajador EMT' },
    { value: 'Cliente', label: 'Cliente' },
  ];

  states = [
    { value: true, label: 'Activo' },
    { value: false, label: 'Inactivo' },
  ];

  ngOnDestroy(){

    this.usersSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.spinner.show();

    this.resetForm();
    this.getUsers();
    this.getCompanies();
  
  }

  resetForm(){

    this.userForm = this.formBuilder.group({
      name1: [{ value: null, disabled: false }, Validators.required],
      last_name1: [{ value: null, disabled: false }, Validators.required],
      last_name2: [{ value: null, disabled: false }, Validators.required],
      company: [{ value: null, disabled: false }, Validators.required],
      email: [{ value: null, disabled: false }, Validators.required],
      role: [{ value: null, disabled: false }, Validators.required],
      active: [{ value: null, disabled: false }, Validators.required],

    });
  }
  

  submitUser(){

    console.log('SUBMIT COMPANY');

    if(this.userForm.invalid){

      this.stateModalAlert = true;  
      this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
      this.modalInfo.type = 'error';
      return
    }


    if(this.stateUser == 'create'){
      this.createUser();
    }else{
      this.updateUser(this.userSelected.uid);
    }
    this.modalAddUser.hide();

  }


  showAddUser(){

    console.log('SHOW MODAL');
    this.resetForm();
    this.stateUser = 'create';
    this.modalAddUser.show();
  }

  


  userSelected: User;
  onreponsDeleteObjectTable($event){

    console.log('User: '+$event.uid);
    this.userSelected = $event;
    this.stateModalAlert = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar a '+$event.name1+ ' '+$event.last_name1;
    this.modalInfo.type = 'warning'    
  }



  onreponseUpdateObjectTable($event){

    console.log('User: '+$event.uid);
    console.log('User: '+$event.company);

    this.userSelected = $event;
    this.stateUser = 'update';
    this.modalAddUser.show();

    this.userForm = this.formBuilder.group({
      name1: [{ value: $event.name1, disabled: false }, Validators.required],
      last_name1: [{ value: $event.last_name1, disabled: false }, Validators.required],
      last_name2: [{ value: $event.last_name2, disabled: false }, Validators.required],
      company: [{ value: $event.companyId, disabled: false }, Validators.required],
      active: [{ value: $event.active, disabled: false }, Validators.required],
      email: [{ value: $event.email, disabled: false }, Validators.required],
      role: [{ value: $event.role, disabled: false }, Validators.required],
    });
    // this.updateUser(this.userSelected.uid);
  }

  onResponseModalAlert($event){

    this.stateModalAlert = $event;
  }

  onReponseDeleteObjectAlert($event){
    console.log('COMPANY: '+this.userSelected.uid);
    this.deleteUser(this.userSelected.uid);
  }

  onReponseStateModal($event){
    this.stateModal = false;
  }



  get name1() {
    return this.userForm.get('name1');
  }

  get last_name1() {
    return this.userForm.get('last_name1');
  }

  get last_name2() {
    return this.userForm.get('last_name2');
  }

  get email() {
    return this.userForm.get('email');
  }

  getUsers(){

    this.usersSubscription = this.usersService.getUsers( ).subscribe({
      next: (resp) => { 
         this.users = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); this.stateModal = true;}
    });

  }

  users: User[] = [];
  // getUsers(){

  //   this.usersSubscription = this.usersService.getUsers( ).subscribe({
  //     next: (resp) => { 
  //        this.users = resp.map( object => ({
  //         name1: object.name1,
  //         name2: object.name2,
  //         email: object.email,
  //         // active: object.active,
  //         // company: object.company._id


  //        }));
  //        console.log('Response: ' + resp); 
  //       },
  //     error: (err) => { console.log('Received an error: ' + err.message)},
  //     complete: () => { console.log('Completed'); this.stateModal = true;}
  //   });

  // }

  createUser(){

    this.usersService.createUser( this.userForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Usuario creada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => {
       console.log('Received an error: ' + err);
       this.stateModalAlert = true;  
       this.modalInfo.messageModal = 'Usuario no fue creado correctamente';
       this.modalInfo.type = 'error'  
    },
    complete: () => {  
        console.log('Completed');
        this.getUsers();
    }
    });

  }

  updateUser(id: string){

 
    this.usersService.updateUser( id, this.userForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Usuario actualizada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getUsers();
    }
    });
  }

  deleteUser(id: string){
  
    this.usersService.deleteUser( id ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Usuario eliminada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getUsers();
    }
    });

  }

  companies: Company[] = [];
  companiesSubscription: Subscription;
  getCompanies(){

    this.companiesSubscription = this.companiesService.getCompanies( ).subscribe({
      next: (resp) => { 
         this.companies = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModal = true;
        this.spinner.hide();
      }
    });

  }
  

}

