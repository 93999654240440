import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Mine } from '../models/mine';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Project } from '../models/backoffice/project';
import { ServiceUnit } from '../models/service-unit';
import { Unit } from '../models/unit';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) { }

  getUnits(): Observable<Unit[]>{
    const url = `${ base_url }/units`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Unit[] }) => 
                resp.objects.map( data => {
                  return {
                    uid: data.uid,
                    name: data.name,
                    active: data.active,
                    state: data.active ? 'Activo' : 'Inactivo',
                    company: data.company,
                    companyName: data.company.name,
                    companyId: data.company._id,
                    users: data.users,
                    type: data.type,
                  }
                })
              )
              )
  }


  
  createProject( unit: Project){
    const url = `${ base_url }/units`;
    return this.http.post( url, unit )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  
  updateProject( id: string, project: Project){
    const url = `${ base_url }/units/${id}`;
    return this.http.put( url, project )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  deleteProject( id: string){
    const url = `${ base_url }/units/${id}`;
    return this.http.delete( url )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

    getUnitsByUser( id: string ): Observable<Project[]>{
    const url = `${ base_url }/units/user/${id}`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Project[] }) => resp.objects )
              );
  }

  getUnitsByUserAndCompany( id: string, companyId: string ): Observable<Project[]>{

    const body = {
      "companyId": companyId
    }
    const url = `${ base_url }/units/user/company/${id}`;
    return this.http.post( url, body )
              .pipe(
                map( (resp: {ok: boolean, objects: Project[] }) => resp.objects )
              );
  }

}
