
         <!--Card-->
    <mdb-card cascade="true" class="card-project" (click)="linkSection()">

        <!--Card image-->
        <!-- <div class="view view-cascade gradient-card-header header-card">
          <h2 class="card-header-title card-title">{{ platform.name }}</h2>
        </div> -->
        <!--/Card image-->
  
        <!-- <mdb-card-body cascade="true" class="body-card">
  
          <mdb-card-text>
            <p class="card-title title-word"> {{ platform.name }} </p>
          </mdb-card-text>
  
          <a class="icons-sm email-ic">
            <mdb-icon fas icon="vector-square" class="px-2"></mdb-icon>
          </a>
  
        </mdb-card-body> -->
        <!--/.Card content-->
  
      </mdb-card>
      <!--/.Card-->

