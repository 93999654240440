import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { DowwnloadService } from 'src/app/services/dowwnload.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  data:any = [];
  objectForm: FormGroup;

  titleModal: string = 'Filtro';

  public myDatePickerOptions: IMyOptions = {
    // Your options
    //  dateFormat: 'DD/MM/YYYY'
  };

  constructor( private dowwnloadService: DowwnloadService, private fb: FormBuilder) { }

  jsonData = [
    { Nombre: "Ejemplo 1", Edad: 25, Ciudad: "Ciudad 1" },
    { Nombre: "Ejemplo 2", Edad: 30, Ciudad: "Ciudad 2" },
    { Nombre: "Ejemplo 3", Edad: 35, Ciudad: "Ciudad 3" },
  ];

  ngOnInit(): void {
    
    this.initForm();
    this.getTypes();
    this.getChannels();
    this.getcrackmeter(); 
  }

  initForm(){

    this.objectForm = this.fb.group({
      'channel': ['', Validators.required],
      'type': ['', Validators.required],
      'fecha_inicio': ['', Validators.required],
      'fecha_fin': ['', Validators.required],
      // 'id': [''],
    });
  }

  dataFilter: any;
  downloadData(){

    // const startDate = new Date('2023-10-25T00:00:00'); // Fecha de inicio
    // const endDate = new Date('2023-10-26T23:59:00');   // Fecha de finalización

    // this.dataFilter = this.data;
    // this.dataFilter = this.dataFilter.filter((object) => object.channel_id == '1508');
    // this.dataFilter = this.dataFilter.filter((object) => {
    //   // Suponemos que object.date es la propiedad que contiene la fecha que deseas filtrar.
    //   const objectDate = new Date(object.fecha);
    //   console.log('DATE: '+objectDate);
    //   // Comprueba si la fecha de objectDate está dentro del rango de startDate y endDate.
    //   return objectDate >= startDate && objectDate <= endDate;
    // });


    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
    const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos'] };
  
    XLSX.writeFile(workbook, 'datos.xlsx');

  // const selectedColumns = this.jsonData.map(({ Nombre, Ciudad }) => ({ Nombre, Ciudad }));
  // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedColumns);
  // const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos'] };

  // XLSX.writeFile(workbook, 'datos.xlsx');
  }

  download(){

    this.getData(this.currentChannel, this.currentType);
  }

  openModal(){

    this.addModal.show();
  }

  changedType(event){

    console.log('EVENT: '+event.gid);
    this.channels = this.channelsOriginal.filter((object) => object.sensor_type_id == event.gid);
    this.currentType = event.name;
  }

  currentType: string;
  currentChannel: string;
  changedChannel(event){

    console.log('EVENT: '+event.id);
    this.currentChannel = event.id;

  }

  getcrackmeter(){

    this.dowwnloadService.getCrackmeter().subscribe({
      next: (resp) => { 
        this.data = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  types: any = [];
  getTypes(){

    this.dowwnloadService.getTypes().subscribe({
      next: (resp) => { 
        this.types = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  channelsOriginal: any = [];
  channels: any = [];
  getChannels(){

    this.dowwnloadService.getChannels().subscribe({
      next: (resp) => { 
        this.channelsOriginal = resp;
        this.channels = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  getData(id: string, type: string){

    this.dowwnloadService.getData(id, type).subscribe({
      next: (resp) => { 
        this.data = resp;
        this.downloadData();
        console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

}
