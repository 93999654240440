
import { Company } from './../../models/backoffice/company';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { Platform } from 'src/app/models/platform';
import { PlatformsService } from './../../services/platforms.service';
import { UsersService } from 'src/app/services/users.service';
import { Project } from 'src/app/models/backoffice/project';
import { ProjectsService } from 'src/app/services/projects.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ServicesunitService } from 'src/app/services/servicesunit.service';
import { ServiceUnit } from 'src/app/models/service-unit';


@Component({
  selector: 'app-platformsback',
  templateUrl: './platformsback.component.html',
  styleUrls: ['./platformsback.component.scss']
})

export class PlatformsbackComponent implements OnInit, OnDestroy {

  @ViewChild('modalAddCompany') modalAddCompany: ModalDirective;

  constructor( private platformsService: PlatformsService, private servicesunitService: ServicesunitService, private projectsService: ProjectsService, private usersService: UsersService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  platforms: Platform[] = [];

  projectsSubscription: Subscription;

  stateCompany: string = 'create'

  stateModal: boolean = false;
  stateModalAlert: boolean = false;
  modalInfo: ModalInfo = new ModalInfo();

  headElements = ['N°', 'Nombre', 'Servicio', 'Herramientas'];
  headTable = ['name', 'serviceName', 'tools'];
  colspanLarge: number = 4;

  platformForm: FormGroup;

  states = [
    { value: true, label: 'Activo' },
    { value: false, label: 'Inactivo' },
  ];

  names = [
    { value: 'Visualización', label: 'Visualización' },
    { value: 'Reportabilidad', label: 'Reportabilidad' },
    { value: 'Gestión de Alertas', label: 'Gestión de Alertas' },
  ];


  ngOnDestroy(){

    this.projectsSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.spinner.show();
    this.resetForm();
    this.getPlatform();

    this.getServices();
  
  }

  resetForm(){

    this.platformForm = this.formBuilder.group({
      name: [{ value: null, disabled: false }, Validators.required],
      service: [{ value: null, disabled: false }, Validators.required],
      url: [{ value: null, disabled: false }, Validators.required],
      download: [{ value: false, disabled: false }, Validators.required],
    });
  }
  

  reponseStateModal($event){
    this.stateModal = false;
  }


  submitCompany(){

    console.log('SUBMIT COMPANY');

    if( this.platformType == 'Reportabilidad'){
      this.platformForm.get('url').setValue('rep/reports');
    }

    if(this.platformForm.invalid){

      this.stateModalAlert = true;  
      this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
      this.modalInfo.type = 'error';
      return
    }

    if(this.stateCompany == 'create'){
      this.createPlatform();
    }else{
      this.updatePlatform(this.companySelected.uid);
    }
    this.modalAddCompany.hide();

  }

  getPlatform(){

    this.projectsSubscription = this.platformsService.getPlatforms( ).subscribe({
      next: (resp) => { 
         this.platforms = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); this.stateModal = true;}
    });

  }

  createPlatform(){
  
    // this.platformForm.value.active = this.activeSelected;
    this.platformsService.createPlatform( this.platformForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Plataforma creada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getPlatform();
    }
    });

  }

  updatePlatform(id: string){
  
    this.platformsService.updatePlatform( id, this.platformForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Plataforma actualizada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getPlatform();
    }
    });
  }

  deletePlatform(id: string){
  
    this.platformsService.deletePlatform( id ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Plataforma eliminada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getPlatform();
    }
    });

  }

  showAddCompany(){

    console.log('SHOW MODAL');
    this.resetForm();
    this.stateCompany = 'create';
    this.modalAddCompany.show();
  }

  onResponseModalAlert($event){

    this.stateModalAlert = $event;
  }

  companySelected: Company;
  onreponsDeleteObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateModalAlert = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.name;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateCompany = 'update';
    this.modalAddCompany.show();

    // this.selectState = new FormControl($event.active);

    // this.platformForm = this.formBuilder.group({
    //   name: [{ value: $event.name, disabled: false }, Validators.required],
    //   service: [{ value: $event.service, disabled: false }, Validators.required],
    //   url: [{ value: $event.url, disabled: false }, Validators.required],
    // });

    console.log('serviceId: '+$event.serviceId);
    this.platformForm.get('name').setValue($event.name);
    this.platformForm.get('url').setValue($event.url);
    // this.platformForm.get('uid').setValue($event.uid);
    this.platformForm.get('service').setValue($event.serviceId);
    this.platformForm.get('download').setValue($event.download);

    // this.updatePlatform(this.companySelected.uid);
  }

  onReponseDeleteObjectAlert($event){
    console.log('COMPANY: '+this.companySelected.uid);
    this.deletePlatform(this.companySelected.uid);
  }


  get name() {
    return this.platformForm.get('name');
  }

  get project() {
    return this.platformForm.get('service');
  }

  get url() {
    return this.platformForm.get('url');
  }

  servicesUnitSubscription: Subscription;
  servicesUnit: ServiceUnit[] = [];

  getServices(){

    this.servicesUnitSubscription = this.servicesunitService.getServices( ).subscribe({
      next: (resp) => { 
         this.servicesUnit = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); this.stateModal = true; this.spinner.hide(); }
    });
  }

  platformType: string = '';
  changedType(event){

    console.log('CHANGE EVENT: '+event);
  
    if(event == 'Visualización'){
      this.platformType = 'Visualización';
    }else{
      this.platformType = 'Reportabilidad';
      // this.platformForm.get('url').setValue('rep/reports');
    }
  }

  

}


