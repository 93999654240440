
	<section class="view intro background-login div-login animated fadeIn slow">
		<div class="div-first">
				<img src="/assets/mydata/pim.svg"  class="p-0 mt-3 logo-emt"> 
				<!-- <img src="/assets/mydata/p2iggris.svg" class="p-0 mt-3 logo-emt"> -->
			<h1 class="title-first mt-5">Plataforma Integral de Monitoreo</h1>
		</div>
		<!-- <h4 class="sub-title-first">Data Visualización y Reportabilidad Ágil</h4> -->
		<div class="div-last">
			<div class="full-bg-img row">
				<div class="container container-login col-10 col-sm-10 col-md-4">
					<!-- <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
						<div class="d-flex justify-content-center">
							<img src="/assets/emt2.png" width="270px" heigth="210px" alt="image">
						</div>
					</div> -->
		
					<!--Form with header-->
					<mdb-card bgColor="login-card" class="wow fadeIn z-depth-1"
						style="animation-name: none; visibility: visible;">
						<mdb-card-body>
							<h2 class="text-center pb-1 title-login">Iniciar Sesión</h2>
							<form [formGroup]="loginForm" (ngSubmit)="login()">
								<div class="md-form md-outline input-login">
									<mdb-icon fas icon="envelope" class="prefix icon-login"></mdb-icon>
									<input mdbInput mdbValidate formControlName="email" type="email"
										class="form-control form-control-lg" id="form9" style="background-color: white;">
									<label for="form9" style="color: black;">Email</label>
									<mdb-error *ngIf="email.invalid && (email.dirty || email.touched)" style="color: red;">Email
										ingresado no es valido</mdb-error>
									<mdb-success *ngIf="email.valid && (email.dirty || email.touched)"
										style="color: white;">Email ingresado es valido</mdb-success>
								</div>
								<div class="md-form md-outline input-login">
									<mdb-icon fas icon="lock" class="prefix icon-login"></mdb-icon>
									<input mdbInput mdbValidate formControlName="password" type="password"
										class="form-control form-control-lg" id="form10" style="background-color: white;">
									<label for="form10" style="color: black;">Contraseña</label>
									<mdb-error *ngIf="password.invalid && (password.dirty || password.touched)"
										style="color: red;">Contraseña ingresada no es valida</mdb-error>
									<mdb-success *ngIf="password.valid && (password.dirty || password.touched)"
										style="color: white;">Contraseña ingresada es valida</mdb-success>
								</div>
								<div class="flex-center">
									<button class="btn-color tittle-white mt-3" mdbBtn type="submit"
										mdbWavesEffect>Entrar</button>
								</div>
								<div class="mt-1 flex-center text-center">
									<i class="fas fa-angle-right pt-3 pr-1 arrow-icon"></i>
									<p class="text-center pt-3 label-login"><a (click)="resendPass()">¿Olvidaste o se bloqueó tu
											clave?</a></p>
								</div>
							</form>
		
						</mdb-card-body>
					</mdb-card>
					<!--/Form with header-->
		
				</div>
			</div>
		</div>
	</section>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
	<div class="modal-dialog modal-side modal-top-right modal-success modal-notify" role="document">

    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <!-- <div class="modal-header">
        <p class="heading lead">Modal Success</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div> -->
      <!--Body-->
      <div class="modal-body">
		<div class="row">
			<div class="col-2 offset-1 pt-2">
				<mdb-icon fas icon="check" size="4x" class="mb-3 animated fadeIn"></mdb-icon>
			</div>
			<div class="col-9 pt-3 text-center">
				<h4>Bienvenido {{this.loginForm.value.email}}</h4>
			</div>
		</div>
      </div>
      <!--Footer-->
      <!-- <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="success" class="waves-effect" mdbWavesEffect>Get it now
          <mdb-icon far icon="gem" class="ml-1"></mdb-icon>
        </a>
        <a type="button" mdbBtn color="success" outline="true" class="waves-effect" mdbWavesEffect (click)="frame.hide()">No, thanks</a>
      </div> -->
    </div>
    <!--/.Content-->
  </div>
</div>

<div>
	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
		(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
</div>


<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
