
<section class="view intro background-login div-login animated fadeIn slow">
	<div class="div-first">
		<div class="d-flex justify-content-center" style="padding-top: 55px;">
			<object data="/assets/logoLogin.svg" width="350" height="200"> </object>
		</div>
	</div>
	<div class="div-last">
		<div class="full-bg-img row">
			<div class="container container-login col-10 col-sm-10 col-md-4">
				<!-- <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
					<div class="d-flex justify-content-center">
						<img src="/assets/emt2.png" width="270px" heigth="210px" alt="image">
					</div>
				</div> -->
	
				<!--Form with header-->
				<mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
					<mdb-card-body>
						<h2 class="text-center pb-3 title-login">Restablecer Contraseña</h2>
						<p class="text-center sub-title-login pt-2 pb-4">Ingresa tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña</p>
						<form [formGroup]="resendForm" (ngSubmit)="resend()"> 
							<div class="md-form md-outline input-login">
								<mdb-icon fas icon="envelope" class="prefix icon-login"></mdb-icon>
								<input mdbInput mdbValidate formControlName="email" type="email"
									class="form-control form-control-lg" id="form9" style="background-color: white;">
								<label for="form9" style="color: black;">Email</label>
								<mdb-error *ngIf="email.invalid && (email.dirty || email.touched)" style="color: red;">Email
									ingresado no es valido</mdb-error>
								<mdb-success *ngIf="email.valid && (email.dirty || email.touched)"
									style="color: white;">Email ingresado es valido</mdb-success>
							</div>
							<div class="text-center">
								<button class="btn-color tittle-white mt-3" mdbBtn type="submit" mdbWavesEffect>Restablecer</button>
								<div class="pt-1 flex-center text-center label-login">
									<i class="fas fa-angle-right pt-3 pr-1 arrow-icon"></i><p class="text-center pt-3 label-login"><a (click)="returnLogin()">Volver al Inicio</a></p>							
								</div>							
							</div>
						</form>
	
					</mdb-card-body>
				</mdb-card>
			</div>
		  </div>
	</div>
</section>





<div>
	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
	(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
</div>