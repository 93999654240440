import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { RecoverComponent } from './recover/recover.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import {MDBSpinningPreloader, MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {AppComponent} from 'src/app/app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InputsModule, ModalModule, WavesModule, ButtonsModule, IconsModule } from 'ng-uikit-pro-standard';
import { CharCounterModule } from 'ng-uikit-pro-standard';
import { ResendComponent } from './resend/resend.component';
import { AuthComponent } from './auth.component';
import { ComponentsModule } from '../components/components.module';


@NgModule({
  declarations: [
    RegisterComponent,
    LoginComponent,
    RecoverComponent,
    ResendComponent,
    AuthComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    InputsModule, WavesModule, ButtonsModule, CharCounterModule,
    IconsModule,
    ComponentsModule,
    ModalModule.forRoot(),
    // MDBBootstrapModulesPro.forRoot()
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AuthModule { }
