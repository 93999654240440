
<section class="view intro background-login div-login animated fadeIn slow">
	<div class="div-first">
		<div class="d-flex justify-content-center" style="padding-top: 55px;">
			<object data="/assets/logoLogin.svg" width="350" height="200"> </object>
		</div>
	</div>
	<div class="div-last">
		<div class="full-bg-img row">
			<div class="container container-login col-10 col-sm-10 col-md-4">
				<!-- <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
					<div class="d-flex justify-content-center">
						<img src="/assets/emt2.png" width="270px" heigth="210px" alt="image">
					</div>
				</div> -->
		
				  <!--Form with header-->
				  <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
					<mdb-card-body>
						<h2 class="text-center pb-3 title-login">Ingresa tu nueva contraseña</h2>
						<!-- <p class="text-center sub-title-login mb-3">Restablecer contraseña</p> -->
						<form [formGroup]="recoverForm" (ngSubmit)="recover()"> 
							<div class="md-form md-outline mb-2 input-login">
							  <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
							  <input mdbInput mdbValidate formControlName="password1" type="password"
									 class="form-control form-control-lg" id="form10" style="background-color: white;">
							  <label for="form10" style="color: black;">Contraseña</label>
							  <mdb-error *ngIf="password1.invalid && (password1.dirty || password1.touched)" style="color: red;">Input invalid
							  </mdb-error>
							  <mdb-success *ngIf="password1.valid && (password1.dirty || password1.touched)" style="color: green;">Input valid
							  </mdb-success>
							</div>
							<div class="md-form md-outline mb-2 input-login">
							  <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
							  <input mdbInput mdbValidate formControlName="password" type="password"
									 class="form-control form-control-lg" id="form12" style="background-color: white;">
							  <label for="form10" style="color: black;">Repite tu contraseña</label>
							  <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)" style="color: red;">Input invalid
							  </mdb-error>
							  <mdb-success *ngIf="password.valid && (password.dirty || password.touched)" style="color: green;">Input valid
							  </mdb-success>
							</div>
							<div class="text-center mt-4">
								<button class="btn-color tittle-white" mdbBtn type="submit" mdbWavesEffect>Restablecer</button>
								<div class="pt-1 flex-center text-center">
									<i class="fas fa-angle-right pt-3 pr-1 arrow-icon"></i><p class="text-center pt-3 label-login"><a (click)="returnLogin()">Volver al Inicio</a></p>							
								</div>
							</div>
						  </form>
		
					</mdb-card-body>
				  </mdb-card>
				  <!--/Form with header-->
		
			</div>
		</div>
	</div>
</section>


<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->



<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
		(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>

