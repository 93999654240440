import { ServicesunitService } from './../../services/servicesunit.service';

import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/backoffice/project';
import { User } from 'src/app/models/user';
import { ProjectsService } from './../../services/projects.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceUnit } from 'src/app/models/service-unit';

@Component({
  selector: 'app-services-unit',
  templateUrl: './services-unit.component.html',
  styleUrls: ['./services-unit.component.scss']
})
export class ServicesUnitComponent implements OnInit {


  servicesUnit: ServiceUnit[] = [];
  backoffice$: any;
  user: User;
  unit: any;

  company: string;
  constructor( private projectService: ProjectsService, private servicesunitService: ServicesunitService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user') || '');
    this.unit = JSON.parse(localStorage.getItem('project') || '');

    this.spinner.show();
    this.getServicesByUnitAndUser();
    console.log('USER: '+this.user.uid);
  
    // this.company = this.unit.name;
  
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
  }

  getServicesByUnitAndUser(){

    this.backoffice$ =this.servicesunitService.getServicesByUnitAndUser( this.user.uid, this.unit.uid ).subscribe({
      next: (resp) => { 
        this.servicesUnit = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.spinner.hide();
      }
    });
  }


  

}
