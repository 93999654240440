// import { ConfigResponse } from 'src/app/pages/applications/applications.component';
import { Platform } from './../models/platform';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Section } from '../models/section';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})

export class PlatformsService {

  constructor(private http: HttpClient) { }

  // getPlatforms(): Observable<Platform[]>{
  //   const url = `${ base_url }/platforms`;
  //   return this.http.get( url )
  //             .pipe(
  //               tap( resp => console.log('platforms: '+resp)),
  //               map( (resp: {ok: boolean, objects: Platform[] }) => resp.objects ),
  //             );
  // }

  getPlatforms(): Observable<Platform[]>{
    const url = `${ base_url }/platforms`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Platform[] }) =>
                resp.objects.map( data => {
                  return {
                    uid: data.uid,
                    name: data.name,
                    url: data.url,
                    // state: data.active ? 'Activo' : 'Inactivo',
                    service: data.service,
                    serviceName: data.service.name,
                    serviceId: data.service._id,
                    download: data.download,
                    // users: data.users,
                    // type: data.type,
                  }
                })
              )
              )
  }


  // getPlatformsByProject(id: string): Observable<Platform[]>{
  //   const url = `${ base_url }/platforms/project/${id}`;
  //   return this.http.get( url )
  //             .pipe(
  //               tap( resp => console.log('platforms: '+resp)),
  //               map( (resp: {ok: boolean, objects: Platform[] }) => resp.objects ),
  //             );
  // }

  getPlatformsByService(id: string): Observable<Platform[]>{
    const url = `${ base_url }/platforms/service/${id}`;
    return this.http.get( url )
              .pipe(
                tap( resp => console.log('platforms: '+resp)),
                map( (resp: {ok: boolean, objects: Platform[] }) => resp.objects ),
              );
  }


  getSectionsByPlatform(idPlatform: string): Observable<Section[]>{
    const url = `${ base_url }/sections/platform/${idPlatform}`;
    return this.http.get( url )
              .pipe(
                tap( resp => console.log('sections: '+resp)),
                map( (resp: {ok: boolean, objects: Section[] }) => resp.objects ),
              );
  }

  createPlatform( platform: Platform){

    let typex: string = '';

    if( platform.name == 'Reportabilidad'){
        typex = "report";
    }else{
      typex = "platform";
    }

    const body = {
          "name": platform.name,
          "service": platform.service,
          "url": platform.url,
          "download": platform.download,
          "type": typex
    }
    
    const url = `${ base_url }/platforms`;
    return this.http.post( url, body )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  
  updatePlatform( id: string, platform: Platform){
    const url = `${ base_url }/platforms/${id}`;
    return this.http.put( url, platform )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  deletePlatform( id: string){
    const url = `${ base_url }/platforms/${id}`;
    return this.http.delete( url )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }



}
