import { CompaniesService } from './../../services/companies.service';
import { Company } from './../../models/backoffice/company';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  @ViewChild('modalAddCompany') modalAddCompany: ModalDirective;

  constructor( private companiesService: CompaniesService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  companies: Company[] = [];

  companiesSubscription: Subscription;

  stateCompany: string = 'create'

  stateModal: boolean = false;
  stateModalAlert: boolean = false;
  modalInfo: ModalInfo = new ModalInfo();

  headElements = ['N°', 'Compañia', 'Iniciales', 'Ubicación', 'Herramientas'];
  headTable = ['name', 'initials', 'country', 'tools'];
  colspanLarge: number = 6;

  companyForm: FormGroup;

  ngOnDestroy(){

    this.companiesSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.spinner.show();
    
    this.resetForm();
    this.getCompanies();
  
  }

  resetForm(){

    this.companyForm = this.formBuilder.group({
      name: [{ value: null, disabled: false }, Validators.required],
      initials: [{ value: null, disabled: false }, Validators.required],
      country: [{ value: null, disabled: false }, Validators.required],
    });
  }
  

  reponseStateModal($event){
    this.stateModal = false;
  }


  submitCompany(){

    console.log('SUBMIT COMPANY');

    if(this.companyForm.invalid){

      this.stateModalAlert = true;  
      this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
      this.modalInfo.type = 'error';
      return
    }

    if(this.stateCompany == 'create'){
      this.createCompany();
    }else{
      this.updateCompany(this.companySelected.uid);
    }
    this.modalAddCompany.hide();

  }

  getCompanies(){

    this.companiesSubscription = this.companiesService.getCompanies( ).subscribe({
      next: (resp) => { 
         this.companies = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModal = true;  
        this.spinner.hide();
      }
    });

  }

  createCompany(){
  
    this.companiesService.createCompany( this.companyForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia creada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getCompanies();
    }
    });

  }

  updateCompany(id: string){
  
    this.companiesService.updateCompany( id, this.companyForm.value ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia actualizada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getCompanies();
    }
    });
  }

  deleteCompany(id: string){
  
    this.companiesService.deleteCompany( id ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Compañia eliminada correctamente';
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getCompanies();
    }
    });

  }

  showAddCompany(){

    console.log('SHOW MODAL');
    this.resetForm();
    this.stateCompany = 'create';
    this.modalAddCompany.show();
  }

  onResponseModalAlert($event){

    this.stateModalAlert = $event;
  }

  companySelected: Company;
  onreponsDeleteObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateModalAlert = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.name;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    console.log('COMPANY: '+$event.uid);
    this.companySelected = $event;
    this.stateCompany = 'update';
    this.modalAddCompany.show();

    this.companyForm = this.formBuilder.group({
      name: [{ value: $event.name, disabled: false }, Validators.required],
      initials: [{ value: $event.initials, disabled: false }, Validators.required],
      country: [{ value: $event.country, disabled: false }, Validators.required],
    });
    // this.updateCompany(this.companySelected.uid);
  }

  onReponseDeleteObjectAlert($event){
    console.log('COMPANY: '+this.companySelected.uid);
    this.deleteCompany(this.companySelected.uid);
  }

  get name() {
    return this.companyForm.get('name');
  }

  get initials() {
    return this.companyForm.get('initials');
  }

  get country() {
    return this.companyForm.get('country');
  }
  
}

