import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Mine } from '../models/mine';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})

export class MinesService {

  constructor(private http: HttpClient) { }

  getMines(): Observable<Mine[]>{
    const url = `${ base_url }/mines`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Mine[] }) => resp.objects )
              );
  }



}

