import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Mine } from '../models/mine';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Project } from '../models/backoffice/project';
import { ServiceUnit } from '../models/service-unit';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class ServicesunitService {

  constructor(private http: HttpClient) { }

  // getServices(): Observable<ServiceUnit[]>{
  //   const url = `${ base_url }/services`;
  //   return this.http.get( url )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: ServiceUnit[] }) => resp.objects )
  //             );
  // }

  getServices(): Observable<ServiceUnit[]>{
    const url = `${ base_url }/services`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: ServiceUnit[] }) =>
                resp.objects.map( data => {
                  return {
                    uid: data.uid,
                    name: data.name,
                    active: data.active,
                    state: data.active ? 'Activo' : 'Inactivo',
                    img: data.url,
                    url: data.url,
                    unit: data.unit,
                    unitName: data.unit.name,
                    unitId: data.unit._id,
                    users: data.users,
                    type: data.type,
                  }
                })
              )
              )
  }


  createService( service: ServiceUnit){
    const url = `${ base_url }/services`;
    return this.http.post( url, service )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }


  updateService( id: string, service: ServiceUnit){
    const url = `${ base_url }/services/${id}`;
    return this.http.put( url, service )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  deleteService( id: string){
    const url = `${ base_url }/services/${id}`;
    return this.http.delete( url )
              .pipe(
                map( (resp: {ok: boolean }) => resp )
              );
  }

  getServicesByUnitAndUser( id: string, unit: string ): Observable<ServiceUnit[]>{

    const body = { unit: unit}
    const url = `${ base_url }/services/user/${id}`;
    return this.http.post( url, body )
              .pipe(
                map( (resp: {ok: boolean, objects: ServiceUnit[] }) => resp.objects )
              );
  }

}
