import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Gis } from '../models/gis';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class BackofficeService {

  constructor( private router: Router, private http: HttpClient ) { }

  getBackoffice(): Observable<Gis[]>{
    const url = `${ base_url }/backoffice`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Gis[] }) => resp.objects )
              );
  }
}

