
<!-- <app-navbar></app-navbar> -->
<main class="background-preview animated fadeIn slow">
    <div class="main-view">
        <mdb-breadcrumb customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item class="breadcrumbs-disabled" routerLink="/rep/companies"><i class="fa-solid fa-house house-init mr-1"></i>Compañias</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="breadcrumbs-enabled">Unidades</mdb-breadcrumb-item>
        </mdb-breadcrumb>
        <div class="component-div">
            <div class="image-container">
                <img src="/assets/mydata/mine3.svg" width="120px" alt="image">
                <h1 class="text-center title-image pt-3 m-0"> {{ companyName }} </h1>
            </div>
    
            <div class="card-columns container pb-5 card-config">
    
                <app-cards [objectx]="objectx" [index]="i+1" *ngFor="let objectx of projects; let i=index"></app-cards>
    
            </div>
        </div>
    </div>
</main>

<ngx-spinner bdColor = "rgba(3,128,128,0.7)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>