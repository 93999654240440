import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import { Alerts } from './../../models/alerts';
import { saveAs } from 'file-saver';
import { ReportsService } from './../../services/reports.service';
import { Component, OnInit } from '@angular/core';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ServiceUnit } from 'src/app/models/service-unit';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  myDatePickerOptions: IMyOptions = {
    // Your options
  };

  dateCalendar: any;
  dataSelect: any;
  platformsService: any;

  headElements = ['N°', 'Nombre', 'Herramientas'];
  headTable = ['name', 'tools'];
  colspanLarge: number = 6;

  stateModal: boolean = true;
  project: Project;
  serviceUnit: ServiceUnit;
  user: User;
  
  constructor( private reportsService: ReportsService ) { }

  ngOnInit(): void {

    this.serviceUnit = JSON.parse(localStorage.getItem('serviceUnit'));
    this.user = JSON.parse(localStorage.getItem('user') || '');
    // this.project = JSON.parse(localStorage.getItem('project'));
    this.getAlerts();
  }

  alertsSubscription: Subscription;
  alerts: Alerts[] = [];

  getAlerts( ){

    this.alertsSubscription =this.reportsService.getAlerts( ).subscribe({
      next: (resp) => { 
         this.alerts = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); }
    });
  }


  getAlertsByDate(date: string){

    this.alertsSubscription =this.reportsService.getReportsByDate( date ).subscribe({
      next: (resp) => { 
         this.alerts = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { console.log('Completed'); }
    });
  }

  getSelectedValue(event: any){
    
    this.getAlertsByDate(event);
    console.log('Date Value: '+event); 

  }


  downloadReport( ){

    console.log('Download Report'); 

    this.reportsService.getFileByUrl( this.dataSelect ).subscribe((data: Blob | MediaSource) => {
      let downloadURL = window.URL.createObjectURL(data);
      saveAs(downloadURL);
    })
  }

  getSelectedValueReport(event: any){

    this.dataSelect = event;
    console.log('Data Value: '+event); 

  }

  onreponseDownloadObjectTable(event){

    this.dataSelect = event.url;
    this.downloadReport();

  }

}
