import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-navbar-preview',
  templateUrl: './navbar-preview.component.html',
  styleUrls: ['./navbar-preview.component.scss']
})
export class NavbarPreviewComponent implements OnInit {

  user: User;
  constructor( private router: Router ) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logout(){

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigateByUrl('/login');
  }

  backOffice(){

    this.router.navigateByUrl('/backrep/home');
  }

  goPreview(){}
}
