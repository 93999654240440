
<!--Main Layout-->

<main class="background-preview animated fadeIn fast">
    <div class="main-view">
        <mdb-breadcrumb customClass="white" class="breadcrumbs mt-3 mb-0 pb-0">
            <mdb-breadcrumb-item class="active" class="breadcrumbs-enabled"><i class="fa-solid fa-house mr-1"></i>Backoffice</mdb-breadcrumb-item>
        </mdb-breadcrumb>
        <div class="d-flex justify-content-center icon-preview">
            <img src="/assets/back/backoffice2.svg" width="175px" alt="image">
        </div>
        <div class="div-preview text-center">
            <h1 class="text-center title-view px-0 mx-0">BackOffice</h1>
        </div>
        <div class="card-columns container pb-5 card-config">


            <!-- <app-cards (productoSeleccionado)="verProducto( $event )" [platform]="platform" [index]="i+1"
                *ngFor="let platform of backoffice; let i=index ">
            </app-cards> -->

            <app-cards [objectx]="objectx" [index]="i+1" *ngFor="let objectx of backoffice; let i=index ">
        </app-cards>

        </div>
    </div>
</main>
<!--/Main layout-->

<ngx-spinner bdColor = "rgba(3,128,128,0.7)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>
