import { Platform } from './../../models/platform';
import { PlatformsService } from './../../services/platforms.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import { NgxSpinnerService } from "ngx-spinner";
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(private router: Router, private platformsService: PlatformsService, private spinner: NgxSpinnerService) { }

//   mines = [
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Minera Los Pelambres",
//         "ubicacion": "Salamanca",
//         "uid": "6241df97f3a61e917be97083",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/pelambres.PNG?alt=media&token=de84b494-3095-4d0b-9839-80b3266f1847"
//     },
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Antucoya",
//         "ubicacion": "Antofagasta",
//         "uid": "6241dfa6f3a61e917be97085",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/antacuya.jpg?alt=media&token=c30d4fb8-722d-42df-bc86-cd9952322eea"
//     },
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Centinela",
//         "ubicacion": "Antofagasta",
//         "uid": "62435d7fbf8778358b0f880a",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/Centinela.jpg?alt=media&token=07b5d663-c7e7-4df2-9438-813cf64610e2"
//     }
// ];

  platforms: Platform[] = [];
  platformsSubscription: Subscription;

  project: Project;
  serviceUnit: any;
  user: User;
  
  ngOnInit(): void {

    this.project = JSON.parse(localStorage.getItem('project'));
    this.serviceUnit = JSON.parse(localStorage.getItem('serviceUnit'));
    this.user = JSON.parse(localStorage.getItem('user') || '');
    
    this.spinner.show();
    this.getPlatformsByService( this.serviceUnit.uid  );
    console.log('PROJECT UID: '+this.project.uid)

  }

  getPlatformsByService(id: string){

    this.platformsSubscription =this.platformsService.getPlatformsByService( id ).subscribe({
      next: (resp) => { 
         this.platforms = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.spinner.hide();
      }
    });
  }

  // verProducto( idx: number ){
  //   console.log('ID PROJECT: '+idx);
  //   this.router.navigate( ['home',idx] );
  // }

}
